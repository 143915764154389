import React from "react";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import ViewTipsModal from "../../../ViewTipsModal/ViewTipsModal";
import { tCommon } from "../../../../../../../i18n/useAppTranslation";
import { useGiftCardActions } from "../../../../hooks/useGiftCardActions";
import NoTipsInfo from "../../../ViewTipsModal/components/NoTipsInfo";

const PaymentTipsModal = ({
  isLoading,
  maxAmountToPay,
  giftCard,
  showTipsModal,
}) => {
  const { applyGiftCard } = useGiftCardActions();

  const handleSubmit = () => {
    applyGiftCard.initiate({
      redeemAmount: maxAmountToPay,
      giftCardId: giftCard.id,
      redemptionCode: giftCard.redemptionCode,
      giftCardAvailableAmount: giftCard.availableAmount,
    });
  };

  const onClose = () => {
    showTipsModal.update(false);
  };

  return (
    <>
      <ModalCharge.Content>
        <ViewTipsModal amountToPay={maxAmountToPay} />
        <NoTipsInfo method="giftCard" />
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={onClose}
        isCharging={isLoading}
        onCharge={handleSubmit}
        chargeLabel={tCommon("label.redeem")}
        isChargeDisabled={isLoading}
      />
    </>
  );
};

export default PaymentTipsModal;
