import React from "react";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../../shared/InputCurrency/InputCurrency";
import { tSales } from "../../../../../../../../i18n/useAppTranslation";
import { Select } from "../../../../../../../../shared/Select/Select";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { usePaymentFlow } from "../../../../../hooks/usePaymentFlow";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import RemainingBalanceDue from "../../../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentFormModal = ({
  form,
  errors,
  hasError,
  changeFormValue,
  cardOptions,
  isValid,
  submit,
  isTipsApplying,
  showTipsModal,
}) => {
  const { invoice, isTipsOnlyFlow } = useInvoice();
  const { paymentViewClose } = useInvoiceActions();
  const { isTipsAvailable } = usePaymentFlow();

  const handleSubmit = () => {
    if (isTipsAvailable && !isTipsOnlyFlow) {
      showTipsModal.update(true);
    } else {
      submit();
    }
  };

  return (
    <>
      <div className="col-sm-6 p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </div>
      <div className="col-sm-12 p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.cardReader")}
        </InputLabel>
        <Select
          size="medium"
          isError={hasError("selectedCard")}
          value={form.selectedCard}
          onChange={(option) => changeFormValue("selectedCard", option)}
          options={cardOptions || []}
        />
      </div>
      <div className="col-sm-12 p-b-10">
        <RemainingBalanceDue amount={form.amount} />
      </div>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        isCharging={false}
        onCharge={handleSubmit}
        isChargeDisabled={isTipsApplying}
        isDisabled={!isValid || isTipsApplying}
      />
    </>
  );
};

export default PaymentFormModal;
