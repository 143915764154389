import React, { useState } from "react";
import * as yup from "yup";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { useGiftCardActions } from "../../hooks/useGiftCardActions";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../../shared/InputError/InputError";
import { Input } from "../../../../../shared/Input/Input";
import ApplyGiftAmount from "./ApplyGiftAmount/ApplyGiftAmount";
import { REDEMPTION_CODE_REGEX } from "../../../../../consts/regexps";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import NoTipsInfo from "../ViewTipsModal/components/NoTipsInfo";

const ViewChargeGiftCard = () => {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { validateGiftCard } = useGiftCardActions();
  const [giftCard, setGiftCard] = useState("");
  const { paymentViewClose } = useInvoiceActions();

  const schema = yup.object({
    redemptionCode: yup
      .string()
      .matches(
        REDEMPTION_CODE_REGEX,
        tSales("checkoutInvoice.formError.giftCardNumberInvalid"),
      )
      .required(tSales("checkoutInvoice.formError.giftCardNumberRequired")),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ redemptionCode }) => {
        validateGiftCard
          .initiate({ redemptionCode: "" + redemptionCode })
          .then(setGiftCard);
      },
      initialValues: {
        redemptionCode: "",
      },
    });

  const onClose = () => {
    paymentViewClose();
  };

  return (
    <ModalCharge
      isOpen
      noFooter
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.giftCard")}
      imageSrc={svg.giftCardPrimary}
    >
      {giftCard ? (
        <ApplyGiftAmount giftCard={giftCard} />
      ) : (
        <>
          <ModalCharge.Content>
            <ModalCharge.InputWrap>
              <InputLabel>
                {tSales("checkoutInvoice.formInputLabel.giftCardNumber")}
              </InputLabel>
              <Input
                size="medium"
                value={form.redemptionCode}
                isError={hasError("redemptionCode")}
                onChange={(e) => {
                  changeFormValue("redemptionCode", "" + e.target.value);
                }}
                placeholder={tSales(
                  "checkoutInvoice.formInputPlaceholder.giftCardNumber",
                )}
              />
              {hasError("redemptionCode") && (
                <InputError>{errors.redemptionCode}</InputError>
              )}
            </ModalCharge.InputWrap>
            <NoTipsInfo method="giftCard" />
          </ModalCharge.Content>
          <ModalCharge.Footer
            onClose={onClose}
            isCharging={validateGiftCard.isLoading}
            onCharge={submit}
            chargeLabel={tCommon("label.apply")}
            isChargeDisabled={!isValid || validateGiftCard.isLoading}
          />
        </>
      )}
    </ModalCharge>
  );
};

export default ViewChargeGiftCard;
