import React from "react";
import { Trans } from "react-i18next";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { tCommon, tSales } from "../../../../../../../i18n/useAppTranslation";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { formatCurrency } from "../../../../../../../utilities/general";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import classes from "../ApplyGiftAmount.module.scss";
import RemainingBalanceDue from "../../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentFormModal = ({
  form,
  errors,
  hasError,
  changeFormValue,
  giftCard,
  isValid,
  isLoading,
  showTipsModal,
  submit,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();
  const { isTipsAvailable } = usePaymentFlow();

  const handleSubmit = () => {
    if (isTipsAvailable) {
      showTipsModal.update(true);
    } else {
      submit();
    }
  };

  return (
    <>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <div className={classes.giftAmount}>
          <Trans
            i18nKey={tSales("checkoutInvoice.giftCard.availableAmount")}
            values={{
              amount: formatCurrency(
                giftCard.availableAmount,
                invoice?.currency,
              ),
            }}
            components={{
              b: <b />,
            }}
          />
          <br />
          <br />
          <Trans i18nKey={tSales("checkoutInvoice.giftCard.consumerNotice")} />
        </div>
      </ModalCharge.Content>
      <RemainingBalanceDue amount={form.amount} />
      <ModalCharge.Footer
        onClose={paymentViewClose}
        isCharging={isLoading}
        onCharge={handleSubmit}
        chargeLabel={
          isTipsAvailable ? tCommon("label.continue") : tCommon("label.redeem")
        }
        isChargeDisabled={!isValid || isLoading}
      />{" "}
    </>
  );
};

export default PaymentFormModal;
