import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../../assets/svg";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { dispatch } from "../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../store/checkoutInvoice";
import { PAYMENT_VIEW_TYPES } from "../../../../../../store/checkoutInvoice/consts";

const PaymentTipsModal = ({ form, showTipsModal, isTipsApplying }) => {
  const { invoice, beforeTipAmount, isTipsOnlyFlow } = useInvoice();
  const { chargeCash, resetTip } = useInvoiceActions();

  const totalAmountToPay =
    parseFloat(form.amount) + parseFloat(invoice.tipAmount);

  const handleSubmit = () => {
    if (isTipsOnlyFlow) {
      dispatch(
        checkoutInvoice.actions.paymentViewChange({
          paymentView: PAYMENT_VIEW_TYPES.optionSelection,
        }),
      );
      return;
    }
    chargeCash.initiate({
      amount: totalAmountToPay,
    });
  };

  const onClose = () => {
    if (beforeTipAmount === 0) {
      dispatch(checkoutInvoice.actions.paymentViewClose());
      resetTip();
      return;
    }
    showTipsModal.update(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.cash")}
      imageSrc={svg.cashPrimary}
      onCharge={handleSubmit}
      isCharging={chargeCash.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
    </ModalCharge>
  );
};

export default PaymentTipsModal;
