import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { img } from "../../../../../../assets/img";
import { usePaymentFlow } from "../../../hooks/usePaymentFlow";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import RemainingBalanceDue from "../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentFormModal = ({
  isLoading,
  isValid,
  isTipsApplying,
  submit,
  form,
  errors,
  hasError,
  changeFormValue,
  showTipsModal,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();
  const { isTipsAvailable } = usePaymentFlow();

  const handleSubmit = () => {
    if (isTipsAvailable) {
      showTipsModal.update(true);
    } else {
      submit();
    }
  };

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.careCredit")}
      imageSrc={img.careCredit}
      onCharge={handleSubmit}
      isCharging={isLoading}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
      isChargeDisabled={!isValid || isTipsApplying}
    >
      <>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <div>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.note")}
          </InputLabel>
          <Textarea
            value={form.note}
            onChange={(e) => changeFormValue("note", e.target.value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.note")}
          />
        </div>
        <RemainingBalanceDue amount={form.amount} />
      </>
    </ModalCharge>
  );
};

export default PaymentFormModal;
