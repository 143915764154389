import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import {
  getClearentToken,
  initializeClearentSdk,
} from "../../../../../../../Utils";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import {
  composeAmountSchema,
  composeEmailSchema,
  composeZipCodeSchema,
} from "../../../../SalesCheckoutInvoice.schemas";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import PaymentFormModal from "../components/Manual/PaymentFormModal";
import PaymentTipsModal from "../components/Manual/PaymentTipsModal";

const FormNewCard = ({ isTipsApplying }) => {
  const { tCommon } = useAppTranslation.Common();
  const { invoice, noTipAmount } = useInvoice();
  const { chargeCard } = useInvoiceActions();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { tipsOnlyFlow, showTipsModal, isTipsAvailable } = usePaymentFlow();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: noTipAmount,
      currency: invoice?.currency,
    }),
    saveCard: yup.bool().required(),
    email: composeEmailSchema(),
    zipCode: composeZipCodeSchema(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: async (values) => {
        try {
          setIsSubmitting(true);
          const clearentToken = await getClearentToken();
          const amountToPay = showTipsModal.value
            ? parseFloat(values.amount) + invoice.tipAmount
            : values.amount;
          if (!clearentToken) throw new Error();
          if (isTipsAvailable && !showTipsModal.value && !tipsOnlyFlow) {
            showTipsModal.update(true);
            return;
          }
          chargeCard
            .initiate({
              clearentEmail: values.email,
              clearentZip: values.zipCode,
              saveCard: values.saveCard,
              isSavedCard: false,
              amount: amountToPay,
              clearentToken,
            })
            .catch(() => showTipsModal.update(false));
        } catch {
          showTipsModal.update(false);
          uiNotification.error(tCommon("error.invalidCardDetails"));
        } finally {
          setIsSubmitting(false);
        }
      },
      initialValues: {
        amount: noTipAmount || "",
        saveCard: false,
        email: invoice?.patient?.email || "",
        zipCode: invoice?.patient?.pinCode || "",
      },
    });

  useEffect(() => {
    if (invoice?.clinic?.publicKey) {
      initializeClearentSdk(invoice.clinic.publicKey, "payment-form");
    } else {
      uiNotification.error(tCommon("error.paymentFormInit"));
    }
  }, [invoice?.clinic?.publicKey]);

  const isLoading = isSubmitting || chargeCard.isLoading || isTipsApplying;

  return (
    <div>
      <PaymentFormModal
        isLoading={isLoading}
        isValid={isValid}
        submit={submit}
        form={form}
        errors={errors}
        hasError={hasError}
        changeFormValue={changeFormValue}
        showTipsModal={showTipsModal}
      />
      {showTipsModal.value && (
        <PaymentTipsModal
          isTipsApplying={isTipsApplying}
          form={form}
          showTipsModal={showTipsModal}
          isLoading={isLoading}
          submit={submit}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(FormNewCard);
