import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { img } from "../../../../../../assets/img";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { Input } from "../../../../../../shared/Input/Input";
import classes from "../ViewChargeKlarna.module.scss";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import RemainingBalanceDue from "../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentFormModal = ({
  isLoading,
  isTipsApplying,
  form,
  changeFormValue,
  errors,
  hasError,
  submit,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();
  const [disabledInput, setDisabledInput] = useState(false);

  useEffect(() => {
    setDisabledInput(form.email);
  }, []);

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.klarna")}
      imageSrc={img.klarna}
      imageClassName={classes.titleKlarna}
      onCharge={submit}
      isCharging={isLoading}
      chargeLabel={tCommon("label.continue")}
      isChargeDisabled={isTipsApplying}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount || ""}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>

      <ModalCharge.InputWrap className={classes.modalKlarna}>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.phoneNumber")}
        </InputLabel>
        <PhoneInput
          name="patientPhone"
          size="medium"
          className={classes.phoneKlarna + " klarna-tel-input"}
          country={"us"}
          countryCodeEditable={false}
          value={invoice?.patient?.phoneNumber || form.phoneNumber}
          onChange={(phone, country, event, formattedPhone) => {
            changeFormValue("country", country);
            changeFormValue("phoneNumber", formattedPhone);
          }}
          enableSearch={true}
          preferredCountries={["us"]}
          isError={hasError("phoneNumber")}
        />
        {hasError("phoneNumber") && (
          <InputError>{errors.phoneNumber}</InputError>
        )}
      </ModalCharge.InputWrap>

      <ModalCharge.InputWrap>
        <InputLabel>{tSales("checkoutInvoice.formInputLabel.mail")}</InputLabel>
        <Input
          size="medium"
          name="email"
          value={form.email}
          onChange={(event) => {
            changeFormValue("email", event.target.value);
          }}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.mail")}
          isDisabled={disabledInput}
        />
      </ModalCharge.InputWrap>
      <p className={classes.smsLink}>
        A link will be sent to the patient via SMS to finish the Klarna checkout
        process
      </p>
      <RemainingBalanceDue amount={form.amount} />
    </ModalCharge>
  );
};

export default PaymentFormModal;
