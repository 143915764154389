/**
 *
 * Dashboard Routes
 *
 */

export const DASHBOARD_ROUTES = {
  root: () => "/dashboard",

  notifications: () => "/dashboard/notifications",

  smsNotifications: () => "/dashboard/sms-notifications",

  smsNotificationsPopup: (id) => `/dashboard/sms-notificationspopup/${id}`,

  userLogs: () => "/dashboard/user-logs",

  viewUserLogChanges: ({ objectType, childId, objectId }) =>
    `/dashboard/user-logs/view-changes/${objectType}/${childId}/${objectId}`,

  userLogDiff: ({ type, childId, objectId }) =>
    `/dashboard/user-logs/diff/${type}/${childId}/${objectId}`,

  providerDashboard: () => `/dashboard/provider`,
};

/**
 *
 * Provider Room Routes
 *
 */

export const PROVIDER_ROOM_ROUTES = {
  root: (type) => `/provider-room/${type}`,

  procedureDetail: ({ id, type }) =>
    `/provider-room/procedure-detail/${id}/${type}`,

  procedureNotes: ({ id, patientId, type }) =>
    `/provider-room/notes/view/${id}/${patientId}/${type}`,

  procedureNote: ({ procedureId, patientId, type, noteId }) =>
    `/provider-room/notes/${procedureId}/${patientId}/${type}/${noteId}`,

  procedureConsents: ({ id, type }) => `/provider-room/consent/${id}/${type}`,

  procedureQuestionnaire: ({ id, type }) =>
    `/provider-room/questionnaire/${id}/${type}`,

  procedurePrescription: ({ id, type }) =>
    `/provider-room/prescription/${id}/${type}`,

  procedureHealthDetail: ({ id, type }) =>
    `/provider-room/procedure-health-detail/${id}/${type}`,

  sentToMdQuestionnaire: (id) =>
    `/proveidr-room/questionnaire/${id}/sent-to-md`,
};

/**
 *
 * Settings Routes
 *
 */

export const SETTINGS_ROUTES = {
  inbox: () => "/settings/inbox",

  arTermsOfUse: () => "/settings/ar-terms-of-use",

  hipaaTermsOfUse: () => "/settings/hipaa-terms-of-use",

  syncProfileCalendar: () => "/settings/profile/calendar/sync",

  profile: () => "/settings/profile",

  deletedPatients: () => "/settings/recently-deleted-patients",

  deletedProcedures: () => "/settings/recently-deleted-procedures",

  deletedPaperwork: () => "/settings/recently-deleted-paperwork",

  deletedSurveys: () => "/settings/recently-deleted-surveys",

  deletedNotes: () => "/settings/recently-deleted-notes",

  clinics: () => "/settings/clinics",

  editClinic: (id) => `/settings/clinic/${id}/edit`,

  createClinic: () => "/settings/clinic/create",

  arAccount: () => "/settings/ar-account",

  discountPackages: () => "/settings/discount-packages",

  createDiscountPackage: () => "/settings/discount-packages/create",

  editDiscountPackage: (id) => `/settings/discount-packages/${id}/edit`,

  membershipWallet: () => "/settings/membership-wallet",

  posDisputes: () => "/settings/pos-disputes",

  posDispute: (id) => `/settings/pos-disputes/${id}/view`,

  posDisputeEvidence: ({ id, type }) =>
    `/settings/pos-disputes/${id}/evidence/${type}`,

  posDisputesByType: (type) => `/settings/pos-disputes/${type}`,

  pos: () => "/settings/pos",

  giftCards: () => "/settings/e-giftcards",

  createGiftCard: () => "/settings/e-giftcards/create",

  editGiftCard: (id) => `/settings/e-giftcards/${id}/edit`,

  posQuickButtons: () => "/settings/pos-quick-button",

  createPosQuickButton: () => "/settings/pos-quick-button/add",

  editPosQuickButton: (id) => `/settings/pos-quick-button/${id}/edit`,

  invoiceDisclaimer: () => "/settings/invoice-text",

  users: () => "/settings/users",

  createUser: () => "/settings/users/create",

  editUser: (id) => `/settings/users/${id}/edit`,

  userControls: () => "/settings/user-roles",

  subscriptions: () => "/settings/manage-subscription",

  arInvoices: () => "/settings/manage-invoices",

  resourceUsage: () => "/settings/resource-usage",

  cosmeticQuestionnaires: () => "/settings/questionnaires",

  createCosmeticQuestionnaire: () => "/settings/questionnaires/create",

  editCosmeticQuestionnaire: (id) => `/settings/questionnaires/${id}/edit`,

  healthTimelineQuestionnaires: () => "/settings/procedure-templates",

  consents: () => "/settings/consents",

  editConsent: (id) => `/settings/consent/${id}/edit`,

  createConsent: () => "/settings/consent/create",

  membershipAgreements: () => "/settings/membership-agreements",

  editMembershipAgreement: (id) => `/settings/membership-agreements/${id}/edit`,

  createMembershipAgreement: () => "/settings/membership-agreements/create",

  preTreatment: () => "/settings/pre-treatment-instructions",

  postTreatment: () => "/settings/post-treatment-instructions",

  dotPhrases: () => "/settings/dot-phrases",

  dotPhrase: ({ actionType, id }) =>
    `/settings/dot-phrases/${actionType}/${id}`,

  treatmentPlanTemplates: () => "/settings/treatment-plan-templates",

  editTreatmentPlanTemplate: (id) =>
    `/settings/treatment-plan-templates/edit/${id}`,

  createTreatmentPlanTemplate: () =>
    "/settings/treatment-plan-templates/create",

  surveys: () => "/settings/surveys/list",

  createSurveyTemplate: () => "/settings/surveys/template/create",

  editSurveyTemplate: (id) => `/settings/surveys/template/${id}/edit`,

  viewAllSurveys: (id) => `/settings/surveys/${id}/view-all`,

  viewSurvey: ({ surveyId, id }) =>
    `/settings/surveys/view/${surveyId}/${id}/survey-data`,

  salesGoals: () => "/settings/sales-goals",

  addSalesGoal: () => "/settings/sales-goals/add",

  editSalesGoal: ({ id, clinicId }) =>
    `/settings/sales-goals/${id}/${clinicId}/edit`,

  payrollCommissionReport: () => "/settings/payroll-commission-report",

  dashboardSettings: () => "/settings/dashboad-roles",

  referralSources: () => "/settings/referral-sources",

  setupEPrescription: () => "/settings/setup-eprescription",

  preTreatmentInstructions: () => "/settings/pre-treatment-instructions",

  invoice: ({ id, type }) => `/settings/invoices/${id}/${type}`,

  createProcedureTemplate: () => "/settings/procedure-template/create",

  editProcedureTemplate: (id) => `/settings/procedure-template/${id}/edit`,

  createPostTreatmentInstruction: () =>
    "/settings/post-treatment-instructions/create",

  editPostTreatmentInstruction: (id) =>
    `/settings/post-treatment-instructions/${id}/edit`,

  integrations: {
    shopify: () => "/settings/integrations/shopify",
    qualiphy: () => "/settings/integrations/qualiphy",
    touchMd: () => "/settings/integrations/touch-md",
    zoho: () => "/settings/integrations/zoho",
    hubSpot: () => "/settings/integrations/hubspot",
    mailChimp: () => "/settings/integrations/mailchimp",
    activeCampaign: () => "/settings/integrations/active-campaign",
    constantContact: () => "/settings/integrations/constant-contact",
    zapier: () => "/settings/integrations/zapier",
    cherry: () => "/settings/integrations/cherry",
    leadsApi: () => "/settings/integrations/leads-api",
    aspire: () => "/settings/integrations/aspire",
    evolus: () => "/settings/integrations/evolus",
    integrationReturnUrl: () => "/settings/integrations/integration-return-url",
    quickBooks: () => "/settings/integrations/quickbooks",
    repeatMd: () => "/settings/integrations/repeatMd",
  },

  timeClock: {
    list: () => "/settings/time-clock/list",
    settings: () => "/settings/time-clock/settings",
  },

  questionnaires: {
    simple: {
      create: () => "/settings/questionnaires/create",
      edit: (id) => `/settings/questionnaires/${id}/edit`,
    },
    multi: {
      create: () => "/settings/procedure-template/create",
      edit: (id) => `/settings/procedure-template/${id}/edit`,
    },
  },

  postTreatmentEmail: () => "/settings/post-treatment-email",

  preTreatmentEmail: () => "/settings/pre-treatment-email",

  createPreTreatmentInstruction: () =>
    "/settings/pre-treatment-instructions/create",

  editPreTreatmentInstruction: (id) =>
    `/settings/pre-treatment-instructions/${id}/edit`,

  appointmentEmailSms: () => "/settings/appointment-email-sms",

  appointments: () => "/settings/appointments",

  appointmentReminder: () => "/settings/appointment-reminder",

  createAppointmentReminder: () => "/settings/appointment-reminder/create",

  editAppointmentReminder: (id) => `/settings/appointment-reminder/${id}/edit`,

  configureUri: () => "/settings/configure-uri",

  cancellationPolicy: () => "/settings/cancellation-policy",

  patientPortal: () => "/settings/patient-portal",

  surveyEmailSms: () => "/settings/survey-email-sms",

  editUserPrivilege: ({ id, roleId }) =>
    `/settings/user-privileges/${id}/role/${roleId}`,

  ePrescriptionPrescribers: () => "/settings/eprescription/prescribers",

  ePrescriptionSupportUsers: () => "/settings/eprescription/support-users",

  renewEprescription: ({ prescriberId, planId }) =>
    `/settings/renew-eprescription/${prescriberId}/${planId}`,

  addEprescription: (userType) =>
    `/settings/setup-eprescription/add/${userType}`,

  setupEprescriptionPlan: ({ planId, userId }) =>
    `/settings/setup-eprescription/${planId}/${userId}`,

  procedure: ({ id, type }) => `/settings/procedure-detail/${id}/${type}`,

  manageSubscriptionUpgrade: (featureName) =>
    `/settings/manage-subscription-upgrade/${featureName}`,

  posReconsiliation: () => "/settings/pos-reconciliation",

  posKlarna: () => "/settings/pos-klarna",

  posDashboard: () => "/settings/pos-dashboard",

  posDashboardVerification: (type) =>
    `/settings/pos-dashboard/verification/${type}`,

  posPayments: () => "/settings/pos-payments",

  posPayouts: () => "/settings/pos-payouts",

  posPayout: (id) => `/settings/pos-payouts/${id}/view`,

  posTab: (tab) => `/settings/pos/${tab}`,

  posOnboarding: () => "/settings/pos-onboarding",

  posSetup: ({ type, clinicId }) => `/settings/pos/setup/${type}/${clinicId}`,

  posCardReader: ({ type, clinicId }) =>
    `/settings/pos/card-reader/${type}/${clinicId}`,

  posPaymentSettings: () => "/settings/pos-payment-settings",

  posExpressSetup: () => "/settings/pos/express/setup",

  posSetupClearent: ({ clinicId, detailType }) =>
    `/settings/pos/setup-clearent/${clinicId}/${detailType}`,

  discoutGroups: () => "/settings/discount-groups",

  createDiscountGroup: () => "/settings/discount-groups/create",

  editDiscountGroup: (id) => `/settings/discount-groups/${id}/edit`,

  chartFilters: () => "/settings/chart-filters",

  betaProgram: () => "/settings/beta-program/features-list",
};

/**
 *
 * Business Insights Routes
 *
 */

export const BI_ROUTES = {
  giftCardHistory: (id) => `/business-insight/egift-card/${id}/history`,

  clientsReports: () => "/business-insight/clients",

  clientsReport: (type) => `/business-insight/clients/${type}`,

  clientsReportByInsight: ({ type, id, insightId }) =>
    `/business-insight/clients/${type}/${id}/${insightId}`,

  inventoryReports: () => "/business-insight/inventory",

  inventoryReportByType: (type) => `/business-insight/inventory/${type}`,

  inventoryShopifyOrdersReport: () =>
    `/business-insight/inventory/shopify-orders`,

  inventoryReportByInsight: ({ type, id, insightId }) =>
    `/business-insight/inventory/${type}/${id}/${insightId}`,

  appointmentsReports: () => "/business-insight/appointments",

  appointmentReportByInsight: ({ type, id, insightId }) =>
    `/business-insight/appointments/${type}/${id}/${insightId}`,

  npsReports: () => "/business-insight/reputation-management",

  npsReport: (type) => `/business-insight/reputation-management/${type}`,

  npsReportDetails: ({ type, id, insightId }) =>
    `/business-insight/reputation-management/${type}/${id}/${insightId}`,

  staffReports: () => "/business-insight/staff",

  staffReportByType: (type) => `/business-insight/staff/${type}`,

  staffReportByInsight: ({ type, id, insightId }) =>
    `/business-insight/staff/${type}/${id}/${insightId}`,

  timeCardReports: () => "/business-insight/time-card",

  qualiphyPaidExamsReport: () =>
    "/business-insight/clients/qualiphy-paid-exams",

  noClinicPatientsReport: () =>
    "/business-insight/clients/patients-without-clinic",

  appointmentReportByType: (type) => `/business-insight/appointments/${type}`,

  retentionRateReport: () =>
    "/business-insight/appointments/rebooking-percentage",

  retentionRateDetailsReport: () =>
    "/business-insight/appointments/rebooking-rate-details",

  sales: {
    root: () => "/business-insight/sales",

    reportByType: (type) => `/business-insight/sales/${type}`,

    membershipOverviewReport: () =>
      "/business-insight/sales/membership-overview",

    shopifyProducts: () => "/business-insight/sales/shopify-products",

    shopifySales: () => "/business-insight/sales/shopify-sales",

    summaryReport: () => "/business-insight/sales/daily_sales_report",

    totalReport: () => "/business-insight/sales/gross_sales",

    netSalesReport: () => "/business-insight/sales/net_sales",

    productSalesReport: () => "/business-insight/sales/item_sales",

    categorySalesReport: () => "/business-insight/sales/category_sales",

    packageSalesReport: () => "/business-insight/sales/package_sales",

    pricingVariationsReport: () => "/business-insight/sales/iv_package_sales",

    discountsReport: () => "/business-insight/sales/discounts",

    commissionReport: () => "/business-insight/sales/commission_report",

    tipReport: () => "/business-insight/sales/tip-report",

    cancellationFeesReport: () => "/business-insight/sales/cancelation_fees",

    retailReport: () => "/business-insight/sales/retail_report",

    bdAspireReport: () => "/business-insight/sales/bd_aspire_report",

    refundsReport: () => "/business-insight/sales/refunds_report",

    costToCompanyReport: () => "/business-insight/sales/cost_to_company",

    giftCardsReport: () => "/business-insight/sales/egift_cards",

    giftCardsRevenueReport: () => "/business-insight/sales/egift_cards_revenue",

    paymentMethodsReport: () => "/business-insight/sales/payment_methods",

    taxesReport: () => "/business-insight/sales/taxes",

    treatmentPlansReport: () => "/business-insight/sales/treatment_plans",

    shortTermLiabilityReport: () =>
      "/business-insight/sales/short_term_liability",

    expiredLiabilityReport: () =>
      "/business-insight/sales/short_term_liability/expired",

    expiringLiabilityReport: () =>
      "/business-insight/sales/short_term_liability/expiring",

    staffTreatmentReport: () => "/business-insight/sales/staff_treatments",

    aspireRedemptionReport: () =>
      "/business-insight/sales/redeemed_aspire_certificates",

    membershipRevenueReport: () => "/business-insight/sales/membership_revenue",

    membershipChurnReport: () =>
      "/business-insight/sales/membership_churn_report",

    membershipDeclineReport: () =>
      "/business-insight/sales/membership_decline_report",

    membershipMrrReport: () => "/business-insight/sales/mrr_report",

    servicesPerformedReport: () => "/business-insight/sales/services_performed",
  },

  sample: () => "/business-insight/sample",

  surveys: () => "/business-insight/surveys/view-all",

  survey: ({ surveyId, id }) =>
    `/business-insight/surveys/view/${surveyId}/${id}/survey-data`,

  shareClients: () => "/business-insight/share/clients",

  chartAudit: () => "/business-insight/chart-audit",
};

/**
 *
 * Inventory Routes
 *
 */

export const INVENTORY_ROUTES = {
  activeProducts: () => "/inventory/products/active",

  activeProductsSearch: (term) => `/inventory/products/active/${term}`,

  priceList: () => "/inventory/pricelist",

  shopifyProducts: () => "/inventory/shopify-products",

  pricingVariation: () => "/inventory/standard-packages",

  shopifyOrders: () => "/inventory/shopify-orders",

  createPricingVariation: () => "/inventory/standard-packages/create",

  editPricingVariation: (id) => `/inventory/standard-packages/edit/${id}`,

  inactiveProducts: () => "/inventory/products/inactive",

  reconciliation: () => "/inventory/reconciliation",

  stockTransfers: () => "/inventory/stock-transfers",

  editStockTransfer: (id) => `/inventory/stock-transfers/${id}/edit`,

  createStockTransfer: () => "/inventory/stock-transfers/create",

  viewStockTransfer: (id) => `/inventory/stock-transfers/${id}/view`,

  stockAlertEmails: () => "/inventory/stock-alerts-emails",

  suppliers: () => "/inventory/suppliers",

  supplier: (id) => `/inventory/suppliers/${id}/view`,

  importSuppliers: () => "/inventory/suppliers/import",

  purchaseOrder: () => "/inventory/purchase-order",

  purchaseOrderView: (id) => `/inventory/purchase-order/view/${id}`,

  addPurchaseOrderById: (id) => `/inventory/purchase-order/add/${id}`,

  addPurchaseOrder: () => "/inventory/purchase-order/add",

  editProductWithStatus: ({ statusId, id }) =>
    `/inventory/products/${statusId}/${id}/edit`,

  editProduct: (id) => `/inventory/product/edit/${id}`,

  editProductWithStock: ({ id, isManageStock }) =>
    `/inventory/product/edit/${id}/stock/${isManageStock}`,

  addProduct: () => "/inventory/product/add",

  productCategories: () => "/inventory/products-categories",

  createProductCategory: () => "/inventory/products-categories/create",

  editProductCategory: (id) => `/inventory/products-categories/${id}/edit`,

  treatmentPlanTemplates: () => "/inventory/treatmentPlanTemplates",

  createTreatentPlanTemplate: () => "/inventory/treatmentPlanTemplates/create",

  productCategory: (id) => `/inventory/products/${id}/category`,
};

/**
 *
 * Clients Routes
 *
 */

export const CLIENTS_ROUTES = {
  root: () => "/clients",

  profile: (profileId) => `/clients/profile/${profileId}`,

  vitals: (profileId) => `/clients/vitals/profile/${profileId}`,

  eFax: (profileId) => `/clients/fax/${profileId}/profile/`,

  mergeProfiles: () => "/clients/merge-profiles",

  procedure: ({
    clientId,
    isNotReloadClientProfile,
    isPrescribed,
    procedureId,
  }) =>
    `/clients/profile/${clientId}/${isNotReloadClientProfile}/${isPrescribed}/${procedureId}`,

  profileWithReloadFlag: ({ id, isNotReloadClientProfile }) =>
    `/clients/profile/${id}/${isNotReloadClientProfile}`,

  create: () => "/clients/create",

  clientByType: ({ id, type }) => `/clients/${id}/${type}`,

  bulkImport: () => "/clients/bulk-import",

  scan: () => "/clients/scan",

  procedureNotes: ({ procedureId, clientId, type }) =>
    `/clients/notes/${procedureId}/${clientId}/${type}`,

  treatmentMarkings: ({ procedureId, clientId, type }) =>
    `/clients/treatment-markings/${procedureId}/${clientId}/${type}`,

  procedureConsentDetail: ({ clientId, id, type, consentId }) =>
    `/clients/${clientId}/consent/${id}/${type}/${consentId}`,

  editProcedureHealth: ({
    procedureId,
    clientId,
    serviceId,
    type,
    questionnaireId,
  }) =>
    `/clients/procedure-health/edit/${procedureId}/${clientId}/${serviceId}/${type}/${questionnaireId}`,

  addHealthProcedure: ({ clientId, type, isPrescribed }) =>
    `/clients/procedure-health-add/${clientId}/${type}/${isPrescribed}`,

  consent: (id) => `/clients/profile/consent/${id}`,

  procedureQuestionnaireService: ({ clientId, id, type, serviceId }) =>
    `/clients/${clientId}/questionnaire/${id}/${type}/${serviceId}`,

  procedureQuestionnaire: ({ clientId, id, type }) =>
    `/clients/${clientId}/questionnaire/${id}/${type}`,

  appointmentConsent: ({ clientId, type, appointmentId, consentId }) =>
    `/clients/${clientId}/${type}/${consentId}/consent/${appointmentId}`,

  consentByType: ({ clientId, id, type }) =>
    `/clients/${clientId}/consent/${id}/${type}`,

  procedureInvoice: ({ type, procedureId }) =>
    `/clients/invoice/${type}/${procedureId}`,

  appointmentHealthQuestionnaire: ({
    clientId,
    procedureId,
    type,
    appointmentId,
  }) =>
    `/clients/${clientId}/health-questionnaires/${appointmentId}/${type}/${procedureId}`,

  appointmentQuestionnaire: ({
    clientId,
    consultationId,
    appointmentId,
    serviceId,
  }) =>
    `/clients/${clientId}/appointment-questionnaire/${consultationId}/appointment/${appointmentId}/service/${serviceId}`,

  wallet: ({ actionType, id, type }) => `/${actionType}/wallet/${id}/${type}`,

  upcomingAppointments: ({ actionType, id, type }) =>
    `/${actionType}/upcoming-appointments/${id}/${type}`,

  traceabilityInfo: ({ actionType, procedureId, clientId, type, subType }) =>
    `/${actionType}/traceability-info/${procedureId}/${clientId}/${type}/${subType}`,

  documents: ({ actionType, clientId, type }) =>
    `/${actionType}/documents/${clientId}/${type}`,

  exportProcedures: ({ actionType, clientId, type }) =>
    `/${actionType}/export-procedures/${clientId}/${type}`,

  customerNotes: ({ actionType, clientId, type }) =>
    `/${actionType}/customer-notes/${clientId}/${type}`,

  documentView: ({ actionType, resourceType, documentId, clientId, type }) =>
    `/${actionType}/documents/${resourceType}/${documentId}/${clientId}/${type}`,
};

/**
 *
 *  Sales Routes
 *
 */

export const SALES_ROUTES = {
  root: () => "/sales",

  checkoutInvoice: (id, type) => `/sales/checkout-invoice/${id}/${type}?`,

  virtualTerminal: () => "/sales/virtual-terminal",

  invoices: ({ filterType, filterId }) =>
    `/sales/invoices/${filterType}/${filterId}`,

  invoicePopups: () => "/sales/invoices-popups",

  cashDrawer: () => "/sales/cash-drawer",
};

/**
 *
 * Appointment Routes
 *
 */

export const APPOINTMENT_ROUTES = {
  noShows: () => "/appointment/no-shows",

  config: () => "/appointment/config",

  services: () => "/appointment/services",

  servicesMain: () => "/appointment/services-main",

  verticals: () => "/appointment/verticals",

  reports: () => "/appointment/reports",

  bookingHistory: () => "/appointment/booking-history",

  bookingPortal: () => "/appointment/booking-portal",

  equipmentSchedule: () => "/appointment/equipment-schedule",

  equipmentScheduleEquipment: () =>
    "/appointment/equipment-schedule?t=equipment",

  equipmentScheduleResource: () => "/appointment/equipment-schedule?t=resource",

  createEquipmentSchedule: () => "/appointment/equipment-schedule/create",

  editEquipmentSchedule: (id) => `/appointment/equipment-schedule/${id}/edit`,

  communications: () => "/appointment/communication",

  communicationSpecialInstructions: () =>
    "/appointment/communication?t=specialInstruction",

  communicationInPersonBooking: () =>
    "/appointment/communication?t=inPersonBooking",

  communicationVirtualBooking: () =>
    "/appointment/communication?t=virtualBooking",

  communicationCancellation: () => "/appointment/communication?t=cancellation",

  communicationInPersonReminder: () =>
    "/appointment/communication?t=inPersonReminder",

  communicationVirtualReminder: () =>
    "/appointment/communication?t=virtualReminder",

  communicationCovid: () => "/appointment/communication?t=covidCommunication",

  communicationReschedule: () => "/appointment/communication?t=reschedule",

  communicationSurvey: () => "/appointment/communication?t=survey",

  bookingPortalDomainConfig: () =>
    "/appointment/booking-portal?t=domainConfiguration",

  bookingPortalSettings: () => "/appointment/booking-portal?t=settings",

  bookingPortalWebConfig: () =>
    "/appointment/booking-portal?t=webConfiguration",

  bookingPortalTheme: () => "/appointment/booking-portal?t=theme",

  otherSettings: () => "/appointment/other-settings",

  cancellationPolicy: () => "/appointment/other-settings?t=cancellationPolicy",

  clientPortalAccess: () => "/appointment/other-settings?t=clientPortalAccess",

  apptReminder: () => "/appointment/other-settings?t=appointmentReminder",

  meeting: () => "/appointment/meeting",

  serviceCategory: () => "/appointment/service-category",

  createServiceCategory: () => "/appointment/service-category/create",

  editServiceCategory: (id) => `/appointment/service-category/${id}/edit`,

  createService: () => "/appointment/services/create",

  editService: ({ serviceCategoryId, id }) =>
    `/appointment/services/${serviceCategoryId}/${id}/edit`,

  cloneService: ({ serviceCategoryId, cloneId }) =>
    `/appointment/services/${serviceCategoryId}/${cloneId}/clone`,

  servicePackages: () => "/appointment/services-packages",

  createServicePackage: () => "/appointment/services-packages/create",

  editServicePackage: (id) => `/appointment/services-packages/${id}/edit`,

  cloneServicePackage: (cloneId) =>
    `/appointment/services-packages/${cloneId}/clone`,

  calendar: () => "/appointment/index",

  calendarDate: (date) => `/appointment/index/${date}`,

  calendarProviderView: ({ providerId, clinicId, view }) =>
    `/appointment/provider/${providerId}/clinic/${clinicId}/${view}`,

  calendarProviderViewDate: ({ providerId, clinicId, view, date }) =>
    `/appointment/provider/${providerId}/clinic/${clinicId}/${view}/${date}`,

  providerWeekCalendar: () => "/appointment/providers/calendar",

  providerWeekCalendarDate: ({ date, clinicId }) =>
    `/appointment/providers/calendar/${date}/${clinicId}`,

  calendarAppointment: (id) => `/appointment/view/${id}`,

  resourceSchedule: () => "/appointment/resource-schedule",

  createResourceSchedule: () => "/appointment/resource-schedule/create",

  editResourceSchedule: (id) => `/appointment/resource-schedule/${id}/edit`,

  providerSchedule: () => "/appointment/provider-schedule",

  viewProviderSchedule: (id) => `/appointment/provider-schedule/${id}/view`,

  deleteProviderSchedule: (id) => `/appointment/provider-schedule-delete/${id}`,

  createWithProvider: (providerId) => `/appointment/create/${providerId}`,

  createWithClient: (clientId) => `/appointment/create/client/${clientId}`,

  createWithLead: (leadId) => `/appointment/create/lead/${leadId}`,

  createWithProviderClinicDateTime: ({ providerId, clinicId, date, time }) =>
    `/appointment/create/${providerId}/${clinicId}/${date}/${time}`,

  edit: ({ editEventId, clinicId }) =>
    `/appointment/edit/${editEventId}/${clinicId}`,

  editWithMode: ({ editEventId, mode, clinicId }) =>
    `/appointment/edit/${editEventId}/${mode}/${clinicId}`,

  editWithModeTime: ({ editEventId, mode, time, clinicId }) =>
    `/appointment/edit/${editEventId}/${mode}/${time}/${clinicId}`,
};

/**
 *
 * Inbox Routes
 *
 */

export const INBOX_ROUTES = {
  smsLeadAr: () => "/inbox/sms/lead-ar",

  smsBlackList: () => "/inbox/sms/black-list",

  smsByClinic: (inboxClinicId) => `/inbox/sms/all/${inboxClinicId}`,

  smsCreate: (id) => `/inbox/sms/create/${id}`,

  smsEdit: (id) => `/inbox/sms/edit/${id}`,

  lists: () => "/inbox/lists",

  campaign: () => "/inbox/campaign",

  campaignView: (id) => `/inbox/campaign/${id}`,

  templates: () => "/inbox/templates",
};

/**
 *
 * Md Room Routes
 *
 */

export const MD_ROOM_ROUTES = {
  root: (type) => `/md-room/${type}`,

  consent: ({ id, type }) => `/md-room/consent/${id}/${type}`,

  notes: ({ procedureId, patientId, type, noteId }) =>
    `/md-room/notes/${procedureId}/${patientId}/${type}/${noteId}`,

  questionnaire: ({ id, type }) => `/md-room/questionnaire/${id}/${type}`,

  procedure: ({ id, type }) => `/md-room/procedure-detail/${id}/${type}`,
};

/**
 *
 * Reports Routes
 *
 */

export const REPORTS_ROUTES = {
  root: () => "/reports",

  create: () => "/reports/create",

  edit: (id) => `/reports/${id}/edit`,
};

/**
 *
 * Surveys Routes
 *
 */

export const SURVEYS_ROUTES = {
  dashboard: () => "/surveys/dashboard",

  manage: () => "/surveys/manage",

  createTemplate: () => "/surveys/template/create",

  editTemplate: (id) => `/surveys/template/${id}/edit`,

  dashboardViewAll: () => "/surveys/dashboard/view-all",

  dashboardView: (id) => `/surveys/dashboard/${id}/view-all`,

  view: (id) => `/surveys/view-all/${id}/survey-data`,
};

/**
 *
 * Uncategorized Routes
 *
 */

export const ROUTES = {
  login: () => "/login",

  changePassword: (wpuId) => `/change-password/${wpuId}`,

  authorizeAccess: (id) => `/authorize-access/${id}`,

  autoLogin: (cipherKey) => `/auto-login/${cipherKey}`,

  touchMdSignUp: (type) => `/touch-MD-signup/${type || ""}`,

  candelaSignup: () => "/candela-signup",

  touchMdAccountSetup: () => "/touchmd-account-setup",

  candelaAccountSetup: () => "/candela-account-setup",

  forgotPasswd: () => "/forgot-password",

  resetPasswd: () => "/reset-password",

  blockIp: () => "/block-ip",

  logout: () => "/logout",

  signUpBasic: (inviteKey) => `/signup-basic/${inviteKey || ""}`,

  signUpPremium: (inviteKey) => `/signup-premium/${inviteKey || ""}`,

  accountSetup: (type) => `/account-setup/${type}`,

  acceptAgreement: () => "/accept-agreement",

  upgradeAccountToStripe: () => "/upgrade-account-to-stripe",

  upgradeSubscriptionPlan: () => "/upgrade-subscription-plan",

  twoFactorLogin: () => "/two-factor-login",

  verifyAccount: (code) => `/verify-account/${code}`,

  accountSuccess: () => "/account/success",

  touchMdAccountSuccess: () => "/touchMD-account/success",

  candelaAccountSuccess: () => "/candela-account/success",

  touchMdAccountVerify: (code) => `/verify-touchmd-account/${code}`,

  nationalMedDirectors: () => "/national-medical-directors",

  workspaces: () => "/workspaces",

  jobSimulator: () => "/job-simulator",

  nonDuplicateProfiles: () => "/non-duplicate-profiles",

  klarnaCompletedPayment: () => "/klarna-completed-payment",

  klarnaCancelPayment: () => "/klarna-cancel-payment",

  providerRoom: {
    root: (type) => `/provider-room/${type}`,

    procedureDetail: ({ id, type }) =>
      `/provider-room/procedure-detail/${id}/${type}`,

    procedureNotes: ({ id, patientId, type }) =>
      `/provider-room/notes/view/${id}/${patientId}/${type}`,

    procedureConsents: ({ id, type }) => `/provider-room/consent/${id}/${type}`,

    procedureQuestionnaire: ({ id, type }) =>
      `/provider-room/questionnaire/${id}/${type}`,

    procedurePrescription: ({ id, type }) =>
      `/provider-room/prescription/${id}/${type}`,

    procedureHealthDetail: ({ id, type }) =>
      `/provider-room/procedure-health-detail/${id}/${type}`,

    chartAuditView: (type) => `/provider-room-chart-view/${type}`,
  },

  settings: {
    inbox: () => "/settings/inbox",

    profile: () => "/settings/profile",

    deletedPatients: () => "/settings/recently-deleted-patients",

    deletedProcedures: () => "/settings/recently-deleted-procedures",

    deletedPaperwork: () => "/settings/recently-deleted-paperwork",

    deletedSurveys: () => "/settings/recently-deleted-surveys",

    deletedNotes: () => "/settings/recently-deleted-notes",

    clinics: () => "/settings/clinics",

    arAccount: () => "/settings/ar-account",

    discountPackages: () => "/settings/discount-packages",

    membershipWallet: () => "/settings/membership-wallet",

    posDisputes: () => "/settings/pos-disputes",

    pos: () => "/settings/pos",

    giftCards: () => "/settings/e-giftcards",

    posQuickButtons: () => "/settings/pos-quick-button",

    invoiceDisclaimer: () => "/settings/invoice-text",

    users: () => "/settings/users",

    userControls: () => "/settings/user-roles",

    subscriptions: () => "/settings/manage-subscription",

    arInvoices: () => "/settings/manage-invoices",

    resourceUsage: () => "/settings/resource-usage",

    cosmeticQuestionnaires: () => "/settings/questionnaires",

    healthTimelineQuestionnaires: () => "/settings/procedure-templates",

    consents: () => "/settings/consents",

    membershipAgreements: () => "/settings/membership-agreements",

    preTreatment: () => "/settings/pre-treatment-instructions",

    postTreatment: () => "/settings/post-treatment-instructions",

    dotPhrases: () => "/settings/dot-phrases",

    treatmentPlanTemplates: () => "/settings/treatment-plan-templates",

    surveys: () => "/settings/surveys/list",

    salesGoals: () => "/settings/sales-goals",

    addSalesGoal: () => "/settings/sales-goals/add",

    editSalesGoal: ({ id, clinicId }) =>
      `/settings/sales-goals/${id}/${clinicId}/edit`,

    payrollCommissionReport: () => "/settings/payroll-commission-report",

    dashboardSettings: () => "/settings/dashboad-roles",

    referralSources: () => "/settings/referral-sources",

    setupEPrescription: () => "/settings/setup-eprescription",

    preTreatmentInstructions: () => "/settings/pre-treatment-instructions",

    integrations: {
      efax: () => "/settings/integrations/efax",

      qualiphy: () => "/settings/integrations/qualiphy",

      touchMd: () => "/settings/integrations/touch-md",

      zoho: () => "/settings/integrations/zoho",

      hubSpot: () => "/settings/integrations/hubspot",

      mailChimp: () => "/settings/integrations/mailchimp",

      activeCampaign: () => "/settings/integrations/active-campaign",

      constantContact: () => "/settings/integrations/constant-contact",

      zapier: () => "/settings/integrations/zapier",

      cherry: () => "/settings/integrations/cherry",

      leadsApi: () => "/settings/integrations/leads-api",

      aspire: () => "/settings/integrations/aspire",

      evolus: () => "/settings/integrations/evolus",
    },

    timeClock: {
      list: () => "/settings/time-clock/list",
      settings: () => "/settings/time-clock/settings",
    },

    efaxTemplates: (tab) =>
      `/settings/efax-templates${tab ? `?tab=${tab}` : ""}`,
    createEfaxTemplate: () => "/settings/efax-templates/create",
    editEfaxTemplate: (id) => `/settings/efax-templates/edit/${id}`,

    createEfaxFavorite: () => "/settings/efax-templates/create-favorite",
    editEfaxFavorite: (id) => `/settings/efax-templates/edit-favorite/${id}`,
  },

  sales: {
    checkoutInvoice: (id, type) => `/sales/checkout-invoice/${id}/${type}?`,
  },

  businessInsights: {
    clientsReports: () => "/business-insight/clients",

    inventoryReports: () => "/business-insight/inventory",

    appointmentsReports: () => "/business-insight/appointments",

    npsReports: () => "/business-insight/reputation-management",

    staffReports: () => "/business-insight/staff",

    timeCardReports: () => "/business-insight/time-card",

    qualiphyPaidExamsReport: () =>
      "/business-insight/clients/qualiphy-paid-exams",

    noClinicPatientsReport: () =>
      "/business-insight/clients/patients-without-clinic",

    retentionRateReport: () =>
      "/business-insight/appointments/rebooking-percentage",

    retentionRateDetailsReport: () =>
      "/business-insight/appointments/rebooking-rate-details",

    sales: {
      membershipOverviewReport: () =>
        "/business-insight/sales/membership-overview",

      summaryReport: () => "/business-insight/sales/daily_sales_report",

      totalReport: () => "/business-insight/sales/gross_sales",

      netSalesReport: () => "/business-insight/sales/net_sales",

      productSalesReport: () => "/business-insight/sales/item_sales",

      categorySalesReport: () => "/business-insight/sales/category_sales",

      packageSalesReport: () => "/business-insight/sales/package_sales",

      pricingVariationsReport: () => "/business-insight/sales/iv_package_sales",

      discountsReport: () => "/business-insight/sales/discounts",

      commissionReport: () => "/business-insight/sales/commission_report",

      tipReport: () => "/business-insight/sales/tip-report",

      cancellationFeesReport: () => "/business-insight/sales/cancelation_fees",

      retailReport: () => "/business-insight/sales/retail_report",

      bdAspireReport: () => "/business-insight/sales/bd_aspire_report",

      refundsReport: () => "/business-insight/sales/refunds_report",

      costToCompanyReport: () => "/business-insight/sales/cost_to_company",

      giftCardsReport: () => "/business-insight/sales/egift_cards",

      giftCardsRevenueReport: () =>
        "/business-insight/sales/egift_cards_revenue",

      paymentMethodsReport: () => "/business-insight/sales/payment_methods",

      taxesReport: () => "/business-insight/sales/taxes",

      treatmentPlansReport: () => "/business-insight/sales/treatment_plans",

      shortTermLiabilityReport: () =>
        "/business-insight/sales/short_term_liability",

      expiredLiabilityReport: () =>
        "/business-insight/sales/short_term_liability/expired",

      expiringLiabilityReport: () =>
        "/business-insight/sales/short_term_liability/expiring",

      staffTreatmentReport: () => "/business-insight/sales/staff_treatments",

      aspireRedemptionReport: () =>
        "/business-insight/sales/redeemed_aspire_certificates",

      membershipRevenueReport: () =>
        "/business-insight/sales/membership_revenue",

      membershipChurnReport: () =>
        "/business-insight/sales/membership_churn_report",

      membershipDeclineReport: () =>
        "/business-insight/sales/membership_decline_report",

      membershipMrrReport: () => "/business-insight/sales/mrr_report",

      servicesPerformedReport: () =>
        "/business-insight/sales/services_performed",
    },
  },

  clients: {
    root: () => "/clients",
    profile: (profileId) => `/clients/profile/${profileId}`,
  },

  appointment: {
    noShows: () => "/appointment/no-shows",

    config: () => "/appointment/config",

    services: () => "/appointment/services-main",

    verticals: () => "/appointment/verticals",

    equipmentSchedule: () => "/appointment/equipment-schedule?t=equipment",

    resourceSchedule: () => "/appointment/equipment-schedule?t=resource",

    communicationSpecialInstructions: () =>
      "/appointment/communication?t=specialInstruction",

    communicationInPersonBooking: () =>
      "/appointment/communication?t=inPersonBooking",

    communicationVirtualBooking: () =>
      "/appointment/communication?t=virtualBooking",

    communicationCancellation: () =>
      "/appointment/communication?t=cancellation",

    communicationInPersonReminder: () =>
      "/appointment/communication?t=inPersonReminder",

    communicationVirtualReminder: () =>
      "/appointment/communication?t=virtualReminder",

    communicationCovid: () => "/appointment/communication?t=covidCommunication",

    communicationReschedule: () => "/appointment/communication?t=reschedule",

    communicationSurvey: () => "/appointment/communication?t=survey",

    bookingPortalDomainConfig: () =>
      "/appointment/booking-portal?t=domainConfiguration",

    bookingPortalSettings: () => "/appointment/booking-portal?t=settings",

    bookingPortalWebConfig: () =>
      "/appointment/booking-portal?t=webConfiguration",

    bookingPortalTheme: () => "/appointment/booking-portal?t=theme",

    cancellationPolicy: () =>
      "/appointment/other-settings?t=cancellationPolicy",

    clientPortalAccess: () =>
      "/appointment/other-settings?t=clientPortalAccess",

    apptReminder: () => "/appointment/other-settings?t=appointmentReminder",
  },

  inventory: {
    activeProducts: () => "/inventory/products/active",

    priceList: () => "/inventory/pricelist",

    pricingVariation: () => "/inventory/standard-packages",

    inactiveProducts: () => "/inventory/products/inactive",

    reconciliation: () => "/inventory/reconciliation",

    stockTransfers: () => "/inventory/stock-transfers",

    stockAlertEmails: () => "/inventory/stock-alerts-emails",

    suppliers: () => "/inventory/suppliers",

    purchaseOrder: () => "/inventory/purchase-order",
  },

  maintenance: () => "/maintenance",

  patients: {
    profile: (profileId) => `/clients/profile/${profileId}`,

    vitals: (profileId) => `/vitals/profile/${profileId}`,
  },

  inbox: {
    efaxOrders: () => "/inbox/efax-orders",
  },

  patientConsents: {
    manyByEntity: ({ type, id }) => `/patient-consents/${type}/${id}`,
    one: (id) => `/patient-consents/${id}`,
  },

  patientQuestionnaires: {
    manyByEntity: ({ type, id }) => `/patient-questionnaires/${type}/${id}`,
    one: (id) => `/patient-questionnaires/${id}`,
  },

  createEditCosmeticProcedure: ({
    actionType,
    resourceType,
    procedureId,
    clientId,
    type,
  } = {}) => {
    if (!procedureId) {
      return `/${actionType}/procedure/${resourceType}/${clientId}/${type}`;
    }
    return `/${actionType}/procedure/${resourceType}/${procedureId}/${clientId}/${type}`;
  },

  createEditHealthProcedure: ({
    actionType,
    resourceType,
    procedureId,
    clientId,
    type,
    isPrescribed,
  } = {}) => {
    if (!procedureId) {
      return `/${actionType}/procedure-health/${resourceType}/${clientId}/${type}/${isPrescribed}`;
    }
    return `/${actionType}/procedure-health/${resourceType}/${procedureId}/${clientId}/${type}/${isPrescribed}`;
  },

  procedurePrescription: ({
    actionType,
    procedureId,
    patientId,
    index,
    viewOnly,
    type,
  }) =>
    `/${actionType}/prescription/${procedureId}/${patientId}/${index}/${viewOnly}/${type}`,

  baVideos: ({ actionType, procedureId, clientId, action }) =>
    `/${actionType}/ba-videos/${procedureId}/${clientId}/${action}`,

  waitlist: () => "/waitlist",

  afterPhotos: ({ actionType, procedureId, clientId, action }) =>
    `/${actionType}/after-photos/${procedureId}/${clientId}/${action}`,

  paymentHistory: ({ actionType, clientId, type, tab }) =>
    `/${actionType}/payment-history/${clientId}/${type}/${tab}`,

  medicalHistory: ({ actionType, clientId, type }) =>
    `/${actionType}/medical-history/${clientId}/${type}`,

  leads: () => "/leads",

  invoice: ({ actionType, invoiceId, clientId }) =>
    `/${actionType}/invoice/${invoiceId}/${clientId}`,

  invoiceByType: ({ actionType, invoiceId, clientId, type }) =>
    `/${actionType}/invoice/${invoiceId}/${clientId}/${type}`,

  appointmentQuestionnaire: (id) => `/appointment-questionnaire/${id}`,

  exportProcedure: ({
    actionType,
    clientId,
    type,
    procedureId,
    appointmentId,
  }) =>
    `/${actionType}/export-procedures/${clientId}/${type}/${procedureId}/${appointmentId}`,

  procedurePrescriptionDetail: ({ actionType, procedureId, clientId, type }) =>
    `/${actionType}/prescription/${procedureId}/${clientId}/${type}`,

  procedureConsent: ({
    actionType,
    resourceType,
    procedureId,
    clientId,
    type,
  }) =>
    `/${actionType}/consent/${resourceType}/${procedureId}/${clientId}/${type}`,

  roomProcedureNotes: ({ roomType, procedureId, patientId, type, noteId }) =>
    `/${roomType}/notes/view/${procedureId}/${patientId}/${type}/${noteId}`,

  procedure: ({ actionType, resourceType, procedureId, clientId, type }) =>
    `/${actionType}/procedure/${resourceType}/${procedureId}/${clientId}/${type}`,

  appointmentQuestionnaireList: ({
    actionType,
    procedureId,
    clientId,
    appointmentId,
    type,
    serviceId,
  }) =>
    `/${actionType}/appointment-questionnaire/${procedureId}/view/${clientId}/${appointmentId}/${serviceId}/${type}`,

  procedureQuestionnaireList: ({
    actionType,
    procedureId,
    clientId,
    appointmentId,
    serviceId,
    type,
  }) =>
    `/${actionType}/procedure-questionnaire/${procedureId}/view/${clientId}/${appointmentId}/${serviceId}/${type}`,

  procedureHealth: ({
    actionType,
    resourceType,
    procedureId,
    clientId,
    type,
    isPrescribed,
  }) =>
    `/${actionType}/procedure-health/${resourceType}/${procedureId}/${clientId}/${type}/${isPrescribed}`,

  procedureHealthDetail: ({ actionType, procedureId, clientId, type }) =>
    `/${actionType}/procedure-health-detail/${procedureId}/${clientId}/${type}`,
};
