import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./SelectChip.module.scss";
import { tCommon } from "../../i18n/useAppTranslation";
import { getUnwrapSelected, getWrapSelected } from "./SelectChip.utils";
import { SELECTED_TYPES } from "./SelectChip.consts";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";

export function SelectChip({
  options,
  onChange,
  selected,
  isMulti,
  className,
  emptyText,
  optionClassName,
  activeOptionClassName,
  selectedType,
  emptyTextClassName,
  allowDeselectSingle,
  isDisabled,
}) {
  const unwrapSelected = getUnwrapSelected({
    isMulti,
    selected,
    selectedType,
  });

  const wrapSelected = getWrapSelected({
    options,
    isMulti,
    selectedType,
  });

  const getIsOptionSelected = (option) => {
    const _selected = unwrapSelected();
    return _selected.includes(option.value);
  };

  const handleChange = (option) => {
    const _selected = unwrapSelected();
    if (getIsOptionSelected(option)) {
      if (isMulti || allowDeselectSingle) {
        onChange(wrapSelected(_selected.filter((s) => s !== option.value)));
      }
    } else {
      if (isMulti) {
        onChange(wrapSelected([..._selected, option.value]));
      } else {
        onChange(wrapSelected([option.value]));
      }
    }
  };

  return (
    <div className={cx(classes.root, className)}>
      {options.length > 0 ? (
        options.map((option) => {
          if (!option.isHidden) {
            return (
              <button
                key={option.value}
                onClick={() => handleChange(option)}
                disabled={isDisabled || option.isDisabled}
                className={cx(
                  classes.option,
                  optionClassName,
                  option.className,
                  {
                    [activeOptionClassName || classes.optionActive]:
                      getIsOptionSelected(option),
                    [classes.disabled]: isDisabled || option.isDisabled,
                  },
                )}
              >
                {option.label}
                {option.isLoading && (
                  <CircularProgress
                    className={classes.loader}
                    size="tiny"
                    color="white"
                  />
                )}
              </button>
            );
          }
        })
      ) : (
        <div className={cx(classes.empty, emptyTextClassName)}>{emptyText}</div>
      )}
    </div>
  );
}

const optionValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

const optionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: optionValuePropType.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
});

SelectChip.propTypes = {
  options: PropTypes.arrayOf(optionPropType),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([
    optionPropType,
    PropTypes.arrayOf(optionPropType),
    optionValuePropType,
    PropTypes.arrayOf(optionValuePropType),
  ]),
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  emptyText: PropTypes.string,
  optionClassName: PropTypes.string,
  selectedType: PropTypes.oneOf(Object.values(SELECTED_TYPES)),
  allowDeselectSingle: PropTypes.bool,
  activeOptionClassName: PropTypes.string,
  emptyTextClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectChip.defaultProps = {
  options: [],
  selected: null,
  isMulti: false,
  className: undefined,
  emptyText: tCommon("label.noOptions"),
  optionClassName: undefined,
  selectedType: SELECTED_TYPES.option,
  allowDeselectSingle: false,
};
