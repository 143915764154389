import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import {
  composeAmountSchema,
  composeEmailSchema,
  composeZipCodeSchema,
} from "../../../../SalesCheckoutInvoice.schemas";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import PaymentFormModal from "../components/CardOnFile/PaymentFormModal";
import PaymentTipsModal from "../components/CardOnFile/PaymentTipsModal";

const FormCardOnFile = ({ isTipsApplying }) => {
  const { invoice, noTipAmount } = useInvoice();
  const { chargeCard } = useInvoiceActions();
  const { showTipsModal } = usePaymentFlow();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: noTipAmount,
      currency: invoice?.currency,
    }),
    email: composeEmailSchema(),
    zipCode: composeZipCodeSchema(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: (values) => {
        chargeCard.initiate({
          clearentEmail: values.email,
          clearentZip: values.zipCode,
          isSavedCard: true,
          amount: values.amount,
          clearentCardToken: values.cardOnFile.value,
        });
      },
      initialValues: {
        amount: noTipAmount || "",
        email: invoice?.patient?.email || "",
        zipCode: invoice?.patient?.cardOnFiles?.[0]?.billingZip || "",
        cardOnFile: {
          label: invoice?.patient?.cardOnFiles?.[0]?.number,
          value: invoice?.patient?.cardOnFiles?.[0]?.cardOnFile,
        },
      },
    });

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          isLoading={chargeCard.isLoading}
          isValid={isValid}
          submit={submit}
          form={form}
          errors={errors}
          hasError={hasError}
          changeFormValue={changeFormValue}
          showTipsModal={showTipsModal}
        />
      ) : (
        <PaymentTipsModal
          isTipsApplying={isTipsApplying}
          form={form}
          showTipsModal={showTipsModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(FormCardOnFile);
