import React, { useEffect, useState } from "react";
import cx from "clsx";
import PropTypes from "prop-types";
import classes from "./TipsApplier.module.scss";
import { SelectChip } from "../../../../../boxes/SelectChip/SelectChip";
import { TIP_OPTIONS } from "../../SalesCheckoutInvoice.consts";
import {
  tSales,
  useAppTranslation,
} from "../../../../../i18n/useAppTranslation";
import { CustomTip } from "../../shared/CustomTip/CustomTip";
import { CURRENCY_SYMBOL } from "../../../../../consts/general";
import { prepareAmount } from "../../SalesCheckoutInvoice.utils";
import { formatCurrency } from "../../../../../utilities/general";
import styles from "../../Views/ViewTipsModal/scss/viewTipsModal.module.scss";

export function TipsApplier({
  onApply,
  onApplyCustom,
  tipAmount,
  isApplying,
  currencySymbol,
  subtotalAmount,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [tip, setTip] = useState(TIP_OPTIONS.noTip);

  const isOptionLoading = (option) => {
    return tip === option && isApplying;
  };

  const onApplyTip = (tipOptionValue) => {
    setTip(tipOptionValue);
    if (tipOptionValue !== TIP_OPTIONS.custom) {
      onApply(tipOptionValue);
    }
  };

  useEffect(() => {
    const tipValue = String(prepareAmount(tipAmount / subtotalAmount));
    if (Object.values(TIP_OPTIONS).includes(tipValue)) {
      setTip(tipValue);
    } else {
      setTip(TIP_OPTIONS.custom);
    }
  }, [tipAmount, subtotalAmount]);

  return (
    <div>
      <span className={cx(styles.field, "m-b-5")}>
        {tSales("checkoutInvoice.tips.tipsCalculated", {
          subtotal: formatCurrency(subtotalAmount),
        })}
      </span>
      <SelectChip
        selectedType="optionValue"
        onChange={onApplyTip}
        selected={tip}
        isDisabled={isApplying}
        options={[
          {
            label: tCommon("label.noTip"),
            value: TIP_OPTIONS.noTip,
            isLoading: isOptionLoading(TIP_OPTIONS.noTip),
          },
          {
            label: "15%",
            value: TIP_OPTIONS.fifteenP,
            isLoading: isOptionLoading(TIP_OPTIONS.fifteenP),
            isHidden: subtotalAmount === 0,
          },
          {
            label: "18%",
            value: TIP_OPTIONS.eighteenP,
            isLoading: isOptionLoading(TIP_OPTIONS.eighteenP),
            isHidden: subtotalAmount === 0,
          },
          {
            label: "20%",
            value: TIP_OPTIONS.twentyP,
            isLoading: isOptionLoading(TIP_OPTIONS.twentyP),
            isHidden: subtotalAmount === 0,
          },
          {
            label: tCommon("label.custom"),
            value: TIP_OPTIONS.custom,
          },
        ]}
      />
      {tip === TIP_OPTIONS.custom && (
        <CustomTip
          onSubmit={onApplyCustom}
          value={tipAmount}
          isSubmitting={isApplying}
          className={classes.customTip}
          currencySymbol={currencySymbol}
        />
      )}
    </div>
  );
}

TipsApplier.propTypes = {
  onApply: PropTypes.func.isRequired,
  onApplyCustom: PropTypes.func.isRequired,
  tipAmount: PropTypes.number,
  beforeTipAmount: PropTypes.number,
  isApplying: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string,
};

TipsApplier.defaultProps = {
  tipAmount: 0,
  beforeTipAmount: 0,
  currencySymbol: CURRENCY_SYMBOL.usd,
};
