import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { composeAmountSchema } from "../../../../SalesCheckoutInvoice.schemas";
import { useCardReaders } from "../../../../hooks/useCardReaders";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import { usePaymentsChangeWatcher } from "../../../../hooks/usePaymentsChangeWatcher";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import PaymentFormModal from "../components/SwipeCard/PaymentFormModal";
import PaymentTipsModal from "../components/SwipeCard/PaymentTipsModal";

const FormSwipeCard = ({ isTipsApplying }) => {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, noTipAmount } = useInvoice();
  const { transferPaymentToReader } = useInvoiceActions();
  const { showTipsModal } = usePaymentFlow();

  const { defaultCardReader } = useCardReaders();

  const { watchPaymentsChange } = usePaymentsChangeWatcher({
    onPaymentsUpdated: () =>
      dispatch(checkoutInvoice.actions.paymentViewClose()),
  });

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: noTipAmount,
      currency: invoice?.currency,
    }),
    readerId: yup
      .number()
      .required(tSales("checkoutInvoice.formError.cardReaderRequired")),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: (values) => {
        transferPaymentToReader
          .initiate({
            amount: values.amount,
            cardReaderId: values.readerId,
          })
          .then(() => {
            watchPaymentsChange.initiate();
            uiNotification.success(
              tSales("checkoutInvoice.success.transferPaymentToReader"),
            );
          });
      },
      initialValues: {
        amount: noTipAmount || "",
        readerId: defaultCardReader?.id,
      },
    });

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          form={form}
          hasError={hasError}
          changeFormValue={changeFormValue}
          errors={errors}
          watchPaymentsChange={watchPaymentsChange}
          transferPaymentToReader={transferPaymentToReader}
          isValid={isValid}
          isTipsApplying={isTipsApplying}
          submit={submit}
          showTipsModal={showTipsModal}
        />
      ) : (
        <PaymentTipsModal
          isTipsApplying={isTipsApplying}
          form={form}
          showTipsModal={showTipsModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(FormSwipeCard);
