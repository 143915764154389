import { useEffect, useState } from "react";
import { useInvoice } from "./invoice/useInvoice";

export const usePaymentFlow = () => {
  const { invoice, isStartStage, isTipsEnabled, beforeTipAmount } =
    useInvoice();

  const [isTipsOnlyFlow, setIsTipsOnlyFlow] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);

  const isTipsAvailable = isStartStage && isTipsEnabled();

  useEffect(() => {
    if (beforeTipAmount === 0 && invoice.tipAmount > 0) {
      setIsTipsOnlyFlow(true);
    }
  }, [beforeTipAmount, invoice.tipAmount]);

  return {
    isTipsOnlyFlow,
    showTipsModal: {
      value: showTipsModal,
      update: setShowTipsModal,
    },
    isTipsAvailable,
  };
};
