import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../../assets/svg";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import NoTipsInfo from "../../ViewTipsModal/components/NoTipsInfo";

const PaymentTipsModal = ({
  isTipsApplying,
  maxAmountToPay,
  showTipsModal,
}) => {
  const { chargeWallet } = useInvoiceActions();

  const handleSubmit = () => {
    chargeWallet.initiate({
      amount: maxAmountToPay,
    });
  };

  const onClose = () => {
    showTipsModal.update(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.arWallet")}
      imageSrc={svg.arWalletPrimary}
      onCharge={handleSubmit}
      isCharging={chargeWallet.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={maxAmountToPay} />
      <NoTipsInfo method="wallet" />
    </ModalCharge>
  );
};

export default PaymentTipsModal;
