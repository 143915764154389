import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import * as yup from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import {
  chargePaymentStripeCardReader,
  fetchAllClinics,
  getCardreaders,
} from "../../../../../../../Actions/Sales/salesActions";
import Loader from "../../../../../../../Components/Common/Loader";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import { composeAmountSchema } from "../../../../SalesCheckoutInvoice.schemas";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import PaymentFormModal from "../components/SwipeCard/PaymentFormModal";
import PaymentTipsModal from "../components/SwipeCard/PaymentTipsModal";
import { uiNotification } from "../../../../../../../services/UINotificationService";

const FormSwipeCard = ({
  getCardreaders,
  fetchAllClinics,
  cardReadersList,
  allClinics,
  chargePaymentStripeCardReader,
  isTipsApplying,
}) => {
  const { invoice, refetchInvoice, noTipAmount } = useInvoice();
  const { showTipsModal } = usePaymentFlow();

  const [userData] = useState(JSON.parse(localStorage.getItem("userData")));
  const [cardReaderList, setCardReaderList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: noTipAmount,
      currency: invoice?.currency,
    }),
    selectedCard: yup.object().required("Please select a card reader!"),
  });

  useEffect(() => {
    if (userData?.account?.id) {
      fetchAllClinics();
      getCardreaders(userData.account.id);
    }
  }, [userData]);

  useEffect(() => {
    if (cardReadersList?.length && allClinics?.length) {
      setCardReaderList(getListCardReaders());
    }
  }, [cardReadersList, allClinics]);

  const cardOptions =
    cardReaderList?.length &&
    cardReaderList.map((c) => ({
      label: `${c.serialNumber} (${c.clinicName})`,
      value: c.serialNumber,
      id: c.id,
      collectSignature: c.collectSignature,
    }));

  const { form, errors, changeFormValue, hasError, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: () => {
        chargePaymentCardreaderReq();
      },
      initialValues: {
        amount: noTipAmount || "",
        selectedCard: null,
      },
    });

  const chargePaymentCardreaderReq = (totalAmount = form.amount) => {
    let formData = {
      transaction_type: "terminal_emv",
      provider: "stripe",
      pos_device: parseInt(form.selectedCard.id),
      invoice: parseInt(invoice.id),
      account_id: userData.account.id,
      amount: totalAmount,
    };

    setShowLoader(true);
    chargePaymentStripeCardReader(formData)
      .then(() => {
        const initialTransactions = invoice?.paymentTransaction;
        const initialTransactionsAmount = initialTransactions?.totalAmount || 0;
        const cardReaderCollectsSignature = Boolean(
          form.selectedCard.collectSignature,
        );

        uiNotification.success("Payment sent to card reader!");

        let unchangedIterations = 0;
        const maxUnchangedIterations = 60;

        const fetchInvoiceDetailInterval = setInterval(() => {
          refetchInvoice().then((response) => {
            const currentTransactions = response.data.paymentTransaction;
            const curentTransactionsAmount =
              currentTransactions?.totalAmount || 0;

            if (
              !isEqual(
                initialTransactions?.payments,
                currentTransactions?.payments,
              ) &&
              curentTransactionsAmount > initialTransactionsAmount
            ) {
              if (cardReaderCollectsSignature) {
                if (response.data.paymentSignature) {
                  setShowLoader(false);
                  clearInterval(fetchInvoiceDetailInterval);
                  dispatch(checkoutInvoice.actions.paymentViewClose());
                }
              } else {
                setShowLoader(false);
                clearInterval(fetchInvoiceDetailInterval);
                dispatch(checkoutInvoice.actions.paymentViewClose());
              }
            } else {
              unchangedIterations++;

              if (
                response.data.status !== "pending" ||
                unchangedIterations >= maxUnchangedIterations
              ) {
                clearInterval(fetchInvoiceDetailInterval); // Stop interval
                setShowLoader(false); // Hide loader
                uiNotification.error("Payment Failed!"); // Show fail message
                showTipsModal.update(false);
              }
            }
          });
        }, 3000);
      })
      .catch((error) => {
        uiNotification.error(error.message);
        setShowLoader(false);
      });
  };

  const getListCardReaders = () => {
    const cardReaderTable = cardReadersList.map((cardReader) => {
      let getClinicById = allClinics.find(
        (clinic) => clinic.id === cardReader.clinic_id,
      );

      if (getClinicById && cardReader.payworks_status === "active") {
        return {
          serialNumber: cardReader.serial_number,
          clinicName: getClinicById.clinic_name,
          id: cardReader.id,
          isDefault: cardReader.is_default,
          collectSignature: cardReader.collects_signature,
        };
      }
      if (cardReader.clinic_id === 0) {
        return {
          serialNumber: cardReader.serial_number,
          clinicName: userData.account.name,
          id: cardReader.id,
          isDefault: cardReader.is_default,
          collectSignature: cardReader.collects_signature,
        };
      }
      return null;
    });

    return cardReaderTable;
  };

  const getDefaultCardReader = () => {
    const defaultReader = cardReaderList?.find((cardReader) =>
      Boolean(cardReader.isDefault),
    );
    return {
      label: `${defaultReader?.serialNumber} (${defaultReader?.clinicName})`,
      value: defaultReader?.serialNumber,
      id: defaultReader?.id,
      collectSignature: defaultReader?.collectSignature,
    };
  };

  useEffect(() => {
    if (cardReaderList?.length) {
      changeFormValue("selectedCard", getDefaultCardReader());
    }
  }, [cardReaderList?.length]);

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          form={form}
          errors={errors}
          hasError={hasError}
          changeFormValue={changeFormValue}
          cardOptions={cardOptions}
          isValid={isValid}
          submit={submit}
          isTipsApplying={isTipsApplying}
          showTipsModal={showTipsModal}
        />
      ) : (
        <PaymentTipsModal
          showLoader={showLoader}
          submit={chargePaymentCardreaderReq}
          form={form}
          showTipsModal={showTipsModal}
          isTipsApplying={isTipsApplying}
        />
      )}

      <Loader isFullWidth={true} showLoader={showLoader} />
    </div>
  );
};

FormSwipeCard.propTypes = {
  getCardreaders: PropTypes.func,
  fetchAllClinics: PropTypes.func,
  cardReadersList: PropTypes.array,
  allClinics: PropTypes.array,
  chargePaymentStripeCardReader: PropTypes.func,
  chargeStripeCardReader: PropTypes.object,
};

const mapStateToProps = (state) => ({
  cardReadersList: state.SalesReducer.cardReadersList,
  allClinics: state.SalesReducer.allClinics,
  chargeStripeCardReader: state.SalesReducer.chargeStripeCardReader,
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getCardreaders, fetchAllClinics, chargePaymentStripeCardReader },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FormSwipeCard));
