import React, { Component } from 'react';
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import validator from 'validator';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import {
  fetchSelectedClinic,
  createClinic,
  updateSelectedClinic,
  fetchAllTimezone,
  fetchAllCountries,
  deleteClinic,
  fetchDefaultClinicData,
  exportEmptyData
} from "../../../../Actions/clinicsActions.js";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { isPositiveNumber } from "../../../../Utils/services.js";
import Loader from '../../../../Components/Common/Loader.js'
import TagsInput from 'react-tagsinput'
import Autocomplete from 'react-google-autocomplete';
import momentTimeZone from "moment-timezone";
import { withCurrentAccountQuery } from '../../../../api/queries/useAccountQuery.js';
import { ACCOUNT_TIME_FORMATS } from '../../../../consts/api.js';
import { InputPhone } from '../../../../shared/InputPhone/InputPhone.js';
import { phoneUtil } from '../../../../utilities/phone';
import RestrictionModal from './RestrictionModal.js';
import { ColorSelect } from '../../../../shared/ColorSelect/ColorSelect.js';
import CopyIcon from "../../../../_legacy/images/copy.svg";
import CopyTimeDropdown from './components/CopyTimeDropdown.js';
import classes from "./scss/CopyTimeDropdown.module.scss"

function convertTime12to24(time12h) {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return hours + ':' + minutes;
}

function tConvert(time, is24Format) {
  if (is24Format) {
    return time?.split(":")?.slice(0, 2)?.join(":");
  }

  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    time = time.slice(1);
    time[0] = +time[0] % 12 || 12;
    if (time[0] < 10) {
      time[0] = '0' + time[0]
    }
  }

  delete time[time.length - 1];
  return time.join('');
}

function getAmPm(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? 'AM' : 'PM';
    time[0] = +time[0] % 12 || 12;
  }
  return time[5];
}

const phoneRegex = new RegExp(/^\+[1-9]\d{1,14}$/);

class CreateEditClinics extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      globalLang: languageData.global,
      timezone: '',
      city: '',
      php_timezone: '',
      country_name: '',
      country_code: '',
      defaultCountry: localStorage.getItem('cCode'),
      clinic_color: '',
      clinic_name: '',
      contact_no: '',
      address: '',
      suite_number: '',
      email_special_instructions: '',
      sms_notifications_phone: '',
      clinic_business_hours: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      tax: '',
      clinicData: {},
      userId: userData.user.id,
      userType: userData.user_type,
      id: this.props.match.params.id,
      page: 1,
      appliedClinicId: 0,
      pagesize: 15,
      sortorder: 'asc',
      term: '',
      hasMoreItems: true,
      appointment_notification_emails: [],
      next_page_url: '',
      loadMore: true,
      startFresh: true,
      showLoader: false,
      apply_clinic: "true",
      productPricePop: false,
      scopes: 'business_hours',
      user_changed: false,
      timezoneList: [],
      countryList: [],
      notiEmailArr: [],
      multipleEmailClass: "newInputField notiEmailInput",
      contactClass: 'newInputField',
      notiContactClass: 'newInputField',
      'day-1': false,
      'day-2': false,
      'day-3': false,
      'day-4': false,
      'day-5': false,
      'day-6': false,
      'day-7': false,
      from_time_1: '00:00',
      from_time_2: '00:00',
      from_time_3: '00:00',
      from_time_4: '00:00',
      from_time_5: '00:00',
      from_time_6: '00:00',
      from_time_7: '00:00',
      from_ampm_1: 'AM',
      from_ampm_2: 'AM',
      from_ampm_3: 'AM',
      from_ampm_4: 'AM',
      from_ampm_5: 'AM',
      from_ampm_6: 'AM',
      from_ampm_7: 'AM',
      to_time_1: '00:00',
      to_time_2: '00:00',
      to_time_3: '00:00',
      to_time_4: '00:00',
      to_time_5: '00:00',
      to_time_6: '00:00',
      to_time_7: '00:00',
      to_ampm_1: 'AM',
      to_ampm_2: 'AM',
      to_ampm_3: 'AM',
      to_ampm_4: 'AM',
      to_ampm_5: 'AM',
      to_ampm_6: 'AM',
      to_ampm_7: 'AM',
      disableClass_1: 'fromandtoTime disable col-sm-10 col-xs-9 no-padding',
      disableClass_2: 'fromandtoTime disable col-sm-10 col-xs-9 no-padding',
      disableClass_3: 'fromandtoTime disable col-sm-10 col-xs-9 no-padding',
      disableClass_4: 'fromandtoTime disable col-sm-10 col-xs-9 no-padding',
      disableClass_5: 'fromandtoTime disable col-sm-10 col-xs-9 no-padding',
      disableClass_6: 'fromandtoTime disable col-sm-10 col-xs-9 no-padding',
      disableClass_7: 'fromandtoTime disable col-sm-10 col-xs-9 no-padding',
      from_hours_format_1: 'newSelectField hours-pm from_time_option',
      from_hours_format_2: 'newSelectField hours-pm from_time_option',
      from_hours_format_3: 'newSelectField hours-pm from_time_option',
      from_hours_format_4: 'newSelectField hours-pm from_time_option',
      from_hours_format_5: 'newSelectField hours-pm from_time_option',
      from_hours_format_6: 'newSelectField hours-pm from_time_option',
      from_hours_format_7: 'newSelectField hours-pm from_time_option',
      to_hours_format_1: 'newSelectField hours-pm to_time_option',
      to_hours_format_2: 'newSelectField hours-pm to_time_option',
      to_hours_format_3: 'newSelectField hours-pm to_time_option',
      to_hours_format_4: 'newSelectField hours-pm to_time_option',
      to_hours_format_5: 'newSelectField hours-pm to_time_option',
      to_hours_format_6: 'newSelectField hours-pm to_time_option',
      to_hours_format_7: 'newSelectField hours-pm to_time_option',
      from_time_class_1: 'setting-input-box hours-time from-time-input',
      from_time_class_2: 'setting-input-box hours-time from-time-input',
      from_time_class_3: 'setting-input-box hours-time from-time-input',
      from_time_class_4: 'setting-input-box hours-time from-time-input',
      from_time_class_5: 'setting-input-box hours-time from-time-input',
      from_time_class_6: 'setting-input-box hours-time from-time-input',
      from_time_class_7: 'setting-input-box hours-time from-time-input',
      to_time_class_1: 'setting-input-box hours-time to-time-input',
      to_time_class_2: 'setting-input-box hours-time to-time-input',
      to_time_class_3: 'setting-input-box hours-time to-time-input',
      to_time_class_4: 'setting-input-box hours-time to-time-input',
      to_time_class_5: 'setting-input-box hours-time to-time-input',
      to_time_class_6: 'setting-input-box hours-time to-time-input',
      to_time_class_7: 'setting-input-box hours-time to-time-input',
      defaultClinicData: {},
      isTimeZomeChanged: false,
      showTimeZoneModal: false,
      isRender: false,
      sms_notifications_phoneError: false,
      allow_online_portal: true,
      is_available_ecommerce: false,
      geoLocationClass: "googleLocationInput",
      gvalue: '',
      gstate: '',
      gcity: '',
      zipcode_error: false,
      zipcode: '',
      gcity_error: false,
      clinic_address_error: false,
      clinic_country_Error: false,
      additional_location_booking: '',
      showErrorDeleteClinicModal: false,
      delete_validation_type: '',
      is_tips_enabled: true,
      isCopyScheduleDropdownOpen: null,
    };

    window.onscroll = () => {
      return false;
    }
  }
  componentDidMount() {

    const clinicId = this.props.match.params.id;
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    let isRender = false;
    if (!clinicId) {
      isRender = true;
    }
    window.onscroll = () => {
      return false;
    }
    this.setState({
      edit_clinic_header: (clinicId != undefined) ? languageData.settings['edit_clinic_header'] : languageData.settings['create_clinic_header'],
      clinicId: (clinicId != undefined) ? clinicId : undefined,
      edit_clinic_subheader: languageData.settings['edit_clinic_subheader'],
      edit_clinic_Clinic_name: languageData.settings['edit_clinic_Clinic_name'],
      edit_clinic_contact_no: languageData.settings['edit_clinic_contact_no'],
      edit_clinic_time_zone: languageData.settings['edit_clinic_time_zone'],
      edit_clinic_address: languageData.settings['edit_clinic_address'],
      edit_clinic_city_state: languageData.settings['edit_clinic_city_state'],
      edit_clinic_country: languageData.settings['edit_clinic_country'],
      edit_clinic_notif_email: languageData.settings['edit_clinic_notif_email'],
      edit_clinic_sms_notif_phone: languageData.settings['edit_clinic_sms_notif_phone'],
      edit_clinic_sms_multiple_emails: languageData.settings['edit_clinic_sms_multiple_emails'],
      edit_clinic_tax_settings: languageData.settings['edit_clinic_taxis_available_online_settings'],
      edit_clinic_tax_rate: languageData.settings['edit_clinic_tax_ratis_available_onlinee'],
      edit_clinic_business_hours: languageData.settings['edit_clinic_business_hours'],
      edit_clinic_open_hours: languageData.settings['edit_clinic_open_hours'],
      edit_clinic_close_hours: languageData.settings['edit_clinic_close_hours'],
      edit_clinic_monday: languageData.settings['edit_clinic_monday'],
      edit_clinic_tuesday: languageData.settings['edit_clinic_tuesday'],
      edit_clinic_wednesday: languageData.settings['edit_clinic_wednesday'],
      edit_clinic_thursday: languageData.settings['edit_clinic_thursday'],
      edit_clinic_friday: languageData.settings['edit_clinic_friday'],
      edit_clinic_Saturday: languageData.settings['edit_clinic_Saturday'],
      edit_clinic_sunday: languageData.settings['edit_clinic_sunday'],
      edit_clinic_delete_button: languageData.settings['edit_clinic_delete_button'],
      clinic_delete_warning: languageData.settings['clinic_delete_warning'],
      settings_price_of_products_msg: languageData.settings['settings_price_of_products_msg'],
      settings_you_are_adding: languageData.settings['settings_you_are_adding'],
      settings_plz_select_how_u: languageData.settings['settings_plz_select_how_u'],
      settings_i_dont_want: languageData.settings['settings_i_dont_want'],
      settings_copy_price: languageData.settings['settings_copy_price'],
      settings_clinic: languageData.settings['settings_clinic'],
      settings_select: languageData.settings['settings_select'],
      user_save_btn_text: languageData.settings['user_save_btn_text'],
      yes_option: languageData.settings['yes_option'],
      no_option: languageData.settings['no_option'],
      label_cancel: languageData.global['label_cancel'],
      delete_confirmation: languageData.global['delete_confirmation'],
      saveBtn: languageData.global['saveBtn'],
      editUsers_CancelBtn: languageData.settings['editUsers_CancelBtn'],
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],
      setting_clinic_timezone_change_msg_confirmation: languageData.settings['setting_clinic_timezone_change_msg_confirmation'],
      showLoader: true,
      isRender: isRender
    })
    let formData = {
      'params': {

      }
    }

    if (clinicId) {
      this.props.fetchSelectedClinic(formData, clinicId);
    } else {
      this.props.fetchDefaultClinicData();
    }
    /*this.props.fetchAllTimezone();

    this.props.fetchAllCountries();*/
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showLoader != undefined && props.showLoader == false) {
      props.exportEmptyData()
      return { showLoader: false };
    }
    if (props.clinicData !== undefined && props.clinicData.status === 200 && props.clinicData.data != state.clinicData && props.currentAccountQuery?.data?.preferences) {
      const is24Format = props.currentAccountQuery.data.preferences.timeFormat === ACCOUNT_TIME_FORMATS.h24;
      let notiEmails = (props.clinicData.data.appointment_notification_emails) ? props.clinicData.data.appointment_notification_emails.split(',') : [];

      notiEmails = notiEmails.filter(function(el) {
        return el != '';
      });

      let returnState = {};

      if (props.clinicData.data.clinic_business_hours.length) {
        let hours = props.clinicData.data.clinic_business_hours;
        hours.map((obj, idx) => {
          returnState["day-" + obj.day] = true;
          returnState["from_time_" + obj.day] = tConvert(obj.from_time, is24Format);
          returnState["from_ampm_" + obj.day] = getAmPm(obj.from_time);
          returnState["to_time_" + obj.day] = tConvert(obj.to_time, is24Format);
          returnState["disableClass_" + obj.day] = 'fromandtoTime';
          returnState["to_ampm_" + obj.day] = getAmPm(obj.to_time);
        })
      }
      returnState.clinicData = props.clinicData.data
      returnState.showLoader = false
      returnState.clinic_color = (state.userChanged) ? state.clinic_color : props.clinicData.data.clinic_color
      returnState.clinic_name = (state.userChanged) ? state.clinic_name : props.clinicData.data.clinic_name
      returnState.contact_no = (state.userChanged) ? state.contact_no : props.clinicData.data.contact_no
      returnState.timezone = (state.userChanged) ? state.timezone : props.clinicData.data.timezone
      returnState.php_timezone = (state.userChanged) ? state.php_timezone : props.clinicData.data.php_timezone
      returnState.address = (state.userChanged) ? state.address : props.clinicData.data.address
      returnState.suite_number = (state.userChanged) ? state.suite_number : props.clinicData.data.suite_number
      returnState.city = (state.userChanged) ? state.city : props.clinicData.data.city
      returnState.notiEmailArr = notiEmails
      let appointment_notification_emails = (state.userChanged) ? state.appointment_notification_emails : props.clinicData.data.appointment_notification_emails ? props.clinicData.data.appointment_notification_emails.split(',') : [];
      appointment_notification_emails = appointment_notification_emails.map(str => str.trim());
      returnState.appointment_notification_emails = appointment_notification_emails;
      returnState.tax = (state.userChanged) ? state.tax : props.clinicData.data.tax
      returnState.status = (state.userChanged) ? state.status : props.clinicData.data.status
      returnState.business_hours = (state.userChanged) ? state.business_hours : props.clinicData.data.clinic_business_hours
      returnState.email_special_instructions = (state.userChanged) ? state.email_special_instructions : props.clinicData.data.email_special_instructions
      returnState.sms_notifications_phone = (state.userChanged) ? state.sms_notifications_phone : props.clinicData.data.sms_notifications_phone
      returnState.country_name = (state.userChanged) ? state.country_name : props.clinicData.data.country
      returnState.isRender = true;
      returnState.countryList = (state.userChanged) ? state.countryList : props.clinicData.data.clinic_data.country_list;
      returnState.timezoneList = (state.userChanged) ? state.countryList : props.clinicData.data.clinic_data.timezone_list;
      returnState.timezoneList = returnState.timezoneList.map(e => {
        const timezoneName = e.timezone.split(" ");
        timezoneName.shift();
        const utcOffset = momentTimeZone.tz(new Date, e.php_timezone).format("ZZ");
        const utcOffsetFormatted = `(UTC${utcOffset.split("").splice(0, 3).join("")}:${utcOffset.split("").splice(3).join("")})`
        e.timezone = `${utcOffsetFormatted} ${timezoneName.join(" ")}`;
        return e;
      })
      returnState.clinicList = (state.userChanged) ? state.countryList : props.clinicData.data.clinic_data.clinic_list;
      returnState.allow_online_portal = (state.userChanged) ? state.allow_online_portal : props.clinicData.data.is_available_online == 1 ? true : false;
      returnState.is_available_ecommerce = (state.userChanged) ? state.is_available_ecommerce : props.clinicData.data.is_available_ecommerce == 1 ? true : false;
      returnState.is_tips_enabled = (state.userChanged) ? state.is_tips_enabled : Boolean(props.clinicData.data.is_tips_enabled);
      returnState.gcity = (state.userChanged) ? state.gcity : props.clinicData.data.clinic_city;
      returnState.gstate = (state.userChanged) ? state.gstate : props.clinicData.data.clinic_state;
      returnState.zipcode = (state.userChanged) ? state.zipcode : props.clinicData.data.clinic_zipcode;
      returnState.additional_location_booking = (state.userChanged) ? state.additional_location_booking : props.clinicData.data.additional_location_booking;
      document.getElementById('new-input').value = (state.userChanged) ? state.address : props.clinicData.data.address;
      props.exportEmptyData()
      return returnState;
    } else if (props.countryList !== undefined && props.countryList.status === 200) {
      props.exportEmptyData()
      return {
        countryList: (state.userChanged) ? state.countryList : props.countryList.data,
        showLoader: false
      };
    } else if (props.defaultClinicData !== undefined && props.defaultClinicData.status === 200 && props.defaultClinicData.data != state.defaultClinicData) {
      props.exportEmptyData()
      return {
        countryList: (state.userChanged) ? state.countryList : props.defaultClinicData.data.country_list,
        timezoneList: (state.userChanged) ? state.countryList : props.defaultClinicData.data.timezone_list,
        clinicList: (state.userChanged) ? state.countryList : props.defaultClinicData.data.clinic_list,
        defaultClinicData: (state.userChanged) ? state.countryList : props.defaultClinicData.data,
        allow_online_portal: (state.userChanged) ? state.allow_online_portal : props.defaultClinicData.data.is_available_online ? props.defaultClinicData.data.is_available_online == 1 ? true : false : true,
        showLoader: false
      };
    } else if (props.timezoneList !== undefined && props.timezoneList.status === 200) {
      props.exportEmptyData()
      return {
        timezoneList: (state.userChanged) ? state.timezoneList : props.timezoneList.data,
        showLoader: false
      };
    } else if (props.redirect != undefined && props.redirect == true) {
      props.exportEmptyData()
      props.history.push('/settings/clinics');

    } else {
      return null;
    }
    return null
  }

  phoneNumberChanged = (nextPhone) => {
    if (
      this.state.contactError &&
      phoneUtil.isPossible(phoneUtil.compose(nextPhone))
    ) {
      this.setState({
        contactError: false,
      })
    }
    this.setState({
      contact_no: nextPhone,
      userChanged: true
    });
  }

  phoneNumberChanged1 = (nextPhone) => {
    if (
      this.state.sms_notifications_phoneError &&
      phoneUtil.isPossible(phoneUtil.compose(nextPhone))
    ) {
      this.setState({
        sms_notifications_phoneError: false,
      })
    }
    this.setState({
      sms_notifications_phone: nextPhone,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let arr = [];
    let changedVal = {
      [event.target.name]: value, userChanged: true
    }
    if (target.name === 'timezone') {
      changedVal.isTimeZomeChanged = true
    }
    if (target.name === 'allow_online_portal' && value === true) {
      changedVal.is_available_ecommerce = false;
    }
    if (target.name === 'is_available_ecommerce' && value === true) {
      changedVal.allow_online_portal = false;
    }
    this.setState(changedVal);
  }

  onBlur = (event) => {
    if (validator.isEmail(event.target.value)) {
      let arr = this.state.notiEmailArr;
      if (arr.indexOf(event.target.value) == -1) {// return adjusted time or original string
        arr.push(event.target.value.trim());
      }
      this.setState({ notiEmailArr: arr, userChanged: true, appointment_notification_emails: '', multipleEmailClass: 'newInputField notiEmailInput ' });
    } else {
      this.setState({ appointment_notification_emails: '', multipleEmailClass: 'newInputField notiEmailInput field_error' });
    }
  }

  removeEmail = (event) => {
    let arr = this.state.notiEmailArr;
    delete arr[event.currentTarget.dataset.emailid];

    var array = arr

    arr = arr.filter(function(el) {
      return el != '';
    });
    this.setState({ notiEmailArr: arr });
  }

  maskChange = (newState, oldState, userInput) => {
    const boundary = this.is24TimeFormat() ? 23 : 12;
    const fallback = this.is24TimeFormat() ? '00' : '12';

    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    if (value.endsWith(':') && userInput !== ':') {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    if (cursorPosition == 2) {
      if (value > boundary) {
        value = fallback;
      }
    }
    var tmpArr = value.split(':');
    if (parseInt(tmpArr[0]) > boundary) {
      tmpArr[0] = fallback;
    }
    if (cursorPosition == 5) {
      if (parseInt(tmpArr[1]) >= 60) {
        tmpArr[1] = 59;
        var tmpVal = tmpArr.join(':');
        value = tmpVal;
      }
    }

    if (tmpArr[1] == undefined) {
      if (tmpArr[0] < 10) {

      }
      tmpArr[1] = '00';
    }
    value = tmpArr.join(':');

    return {
      value,
      selection
    };
  }

  toggleTime = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let nameArr = name.split('-');
    let id = nameArr[nameArr.length - 1];
    let tempState = {}
    tempState['day-' + id] = value;
    if (value) {
      tempState['disableClass_' + id] = 'fromandtoTime  col-sm-10 col-xs-9 no-padding'
    } else {
      tempState['disableClass_' + id] = 'fromandtoTime disable  col-sm-10 col-xs-9 no-padding'
    }
    tempState['from_time_' + id] = '00:00'
    tempState['from_ampm_' + id] = 'AM'
    tempState['to_time_' + id] = '00:00'
    tempState['to_ampm_' + id] = 'AM'
    this.setState(tempState);
  }

  showDeleteModal = () => {
    this.setState({ showModal: true })
  }

  dismissModal = () => {
    this.setState({ showModal: false })
  }

  deleteClinic = async () => {
    this.setState({ showLoader: true, hideBtns: true })
    this.dismissModal();
    let cId = this.state.clinicId;
    await this.props.deleteClinic(cId);
    if (this.props.deleteClinicData.message === "delete_clinic_active_membership_error") {
      this.setState({ showErrorDeleteClinicModal: true, delete_validation_type: "memberships" })
    }
    else if (this.props.deleteClinicData.message === "delete_clinic_appointment_error") {
      this.setState({ showErrorDeleteClinicModal: true, delete_validation_type: "appointments" })
    }
    this.setState({ showLoader: false })
  }

  handleSubmit = (event) => {
    let address = document.getElementById('new-input').value;
    this.setState({
      showTimeZoneModal: false,
      isTimeZomeChanged: false,
    })
    //====Frontend validation=================
    let error = false;
    let regularExpression = /^[a-zA-Z]$/;


    this.setState({
      clinic_color_Error: false,
      clinic_name_Error: false,
      contact_no_Error: false,
      timezone_Error: false,
      address_Error: false,
      city_Error: false,
      country_Error: false,
      appointment_notification_emails_Error: false,
      sms_notifications_phone_Error: false,
      tax_Error: false,
      status_Error: false,
      email_special_instructions_Error: false,
      business_hours_Error: false,
      sms_notifications_phoneError: false,
      geoLocationClass: "googleLocationInput",
      gcity_Error: false,
      gstate_Error: false,
    });

    if (!this.state.clinic_color) {
      this.setState({
        clinic_color_Error: true
      })
      error = true;
    }

    if (typeof this.state.clinic_name === undefined || this.state.clinic_name === null || this.state.clinic_name === '' || this.state.clinic_name.trim() == "") {
      this.setState({
        clinic_name_Error: true
      })
      error = true;
    }

    let x = String(this.state.timezone)
    if (typeof x === undefined || x === null || x === '' || x.trim() === '' || x === "select") {
      this.setState({
        timezone_Error: true
      })
      error = true;
    }

    if (typeof address == undefined || address == null || address == '' || address.trim() == "") {
      this.setState({
        address_Error: true
      })
      error = true;
    } else {
      this.setState({
        address_Error: false
      })
    }

    if (typeof this.state.gstate === undefined || this.state.gstate === null || this.state.gstate === '' || this.state.gstate.trim() == "") {
      this.setState({
        gstate_Error: true
      })
      error = true;
    } else {
      this.setState({
        gstate_Error: false
      })
    }


    if (typeof this.state.zipcode === undefined || this.state.zipcode === null || this.state.zipcode === '' || this.state.zipcode.trim() == "") {
      this.setState({
        zipcode_error: true
      })
      error = true;
    } else {
      this.setState({
        zipcode_error: false
      })
    }

    if (typeof this.state.gcity == undefined || this.state.gcity == null || this.state.gcity == '' || this.state.gcity.trim() == '') {
      this.setState({
        gcity_error: true
      })
      error = true;
    } else {
      this.setState({
        gcity_error: false
      })
    }

    if (typeof this.state.country_name == undefined || this.state.country_name == null || this.state.country_name == '' || this.state.country_name.trim() == '' || this.state.country_name == 'select') {
      this.setState({
        country_error: true
      })
      error = true;
    } else {
      this.setState({
        country_error: false
      })
    }

    if (this.state.clinicId) {
      if (typeof this.state.city == undefined || this.state.city == null || this.state.city == '' || this.state.city.trim() == '') {
        this.setState({
          city_Error: true
        })
        error = true;
      } else {
        this.setState({
          city_Error: false
        })
      }
    }

    if (!phoneUtil.isPossible(phoneUtil.compose(this.state.contact_no))) {
      this.setState({
        contactError: true,
      });
      error = true;
    }

    if (
      this.state.sms_notifications_phone &&
      !phoneUtil.isPossible(phoneUtil.compose(this.state.sms_notifications_phone))
    ) {
      this.setState({
        sms_notifications_phoneError: true,
      });
      error = true;
    }

    if (error === true) {
      return;
    }

    let business_hours = [];

    this.state.clinic_business_hours.map((obj, idx) => {
      let dayId = idx + 1;
      let errorState = {}
      errorState['from_time_class_' + dayId] = 'newSelectField hours-time from-time-input';
      errorState['to_time_class_' + dayId] = 'newInputField hours-time to-time-input';
      errorState['from_hours_format_' + dayId] = 'newSelectField hours-pm from_time_option';
      errorState['to_hours_format_' + dayId] = 'newSelectField hours-pm from_time_option';
      this.setState(errorState);
    })
    let errorClinicBusinesshours = false;
    this.state.clinic_business_hours.map((obj, idx) => {
      let dayId = idx + 1;
      if (this.state['day-' + dayId]) {
        let errorState = {}
        let from_time = this.state['from_time_' + dayId];
        let to_time = this.state['to_time_' + dayId];
        let from_hours = this.is24TimeFormat() ? '' : this.state['from_ampm_' + dayId];
        let to_hours = this.is24TimeFormat() ? '' : this.state['to_ampm_' + dayId];
        var appointment_form = "11/24/2014 " + from_time + " " + from_hours;
        var appointment_to = "11/24/2014 " + to_time + " " + to_hours;
        var fromDate = new Date(Date.parse(appointment_form));
        var toDate = new Date(Date.parse(appointment_to));
        if ((from_time == '' || from_time == '00:00') && (to_time == '' || to_time == '00:00')) {
          errorState['from_time_class_' + dayId] = 'newInputField hours-time from-time-input field_error';
          errorState['to_time_class_' + dayId] = 'newInputField hours-time to-time-input field_error';
          errorState['from_hours_format_' + dayId] = 'newSelectField hours-pm from_time_option field_error';
          errorState['to_hours_format_' + dayId] = 'newSelectField hours-pm from_time_option field_error';
          errorClinicBusinesshours = true;


        } else if (from_time == '' || from_time == '00:00') {
          errorState['from_time_class_' + dayId] = 'newInputField hours-time from-time-input field_error';
          errorClinicBusinesshours = true;
        } else if (to_time == '' || to_time == '00:00') {
          errorState['to_time_class_' + dayId] = 'newInputField hours-time to-time-input field_error';
          errorClinicBusinesshours = true;
        } else if (fromDate >= toDate) {
          errorState['from_time_class_' + dayId] = 'newInputField hours-time from-time-input field_error';
          errorState['to_time_class_' + dayId] = 'newInputField hours-time to-time-input field_error';
          errorState['from_hours_format_' + dayId] = 'newInputField hours-pm from_time_option field_error';
          errorState['to_hours_format_' + dayId] = 'newInputField hours-pm from_time_option field_error';
          errorClinicBusinesshours = true;
        } else {
          errorState['from_hours_format_' + dayId] = 'newSelectField hours-pm from_time_option';
          errorState['to_hours_format_' + dayId] = 'newSelectField hours-pm from_time_option';
          let x = this.is24TimeFormat() ? this.state['from_time_' + dayId] : convertTime12to24(this.state['from_time_' + dayId] + ' ' + this.state['from_ampm_' + dayId])
          let y = this.is24TimeFormat() ? this.state['to_time_' + dayId] : convertTime12to24(this.state['to_time_' + dayId] + ' ' + this.state['to_ampm_' + dayId])
          business_hours.push({ day: dayId, from_time: x, to_time: y });
        }
        this.setState(errorState);
      }
    })
    this.setState({ business_hours: business_hours })
    if (errorClinicBusinesshours) {
      toast.dismiss();
      toast.error(this.state.globalLang.error_please_a_valid_time);
      return false
    }
    if (this.state.isTimeZomeChanged && this.props.match.params.id > 0) {
      this.setState({ showTimeZoneModal: true })
    } else {
      this.handleFormSubmit(business_hours)
    }
  };

  handleFormSubmit = (business_hours) => {
    this.setState({
      showTimeZoneModal: false,
      isTimeZomeChanged: false
    })
    localStorage.setItem('sortOnly', true);
    let formData = {
      clinic_color: this.state.clinic_color,
      clinic_name: this.state.clinic_name,
      contact_no: phoneUtil.compose(this.state.contact_no),
      timezone: this.state.timezone,
      php_timezone: this.state.php_timezone,
      address: document.getElementById('new-input').value,
      suite_number: this.state.suite_number,
      city: this.state.clinicId ? this.state.city : this.state.gcity + ',' + this.state.gstate,
      country_name: this.state.country_name,
      country: this.state.country_name,
      sms_notifications_phone: phoneUtil.compose(this.state.sms_notifications_phone),
      is_available_online: this.state.allow_online_portal == true ? 1 : 0,
      is_available_ecommerce: this.state.is_available_ecommerce == true ? 1 : 0,
      is_tips_enabled: Number(this.state.is_tips_enabled),
      clinic_city: this.state.gcity,
      clinic_state: this.state.gstate,
      clinic_zipcode: this.state.zipcode,
      additional_location_booking: this.state.additional_location_booking
    };

    if (business_hours) {
      formData.business_hours = business_hours
    }

    formData.appointment_notification_emails = (this.state.appointment_notification_emails && this.state.appointment_notification_emails.length > 0) ? this.state.appointment_notification_emails.join(',') : '';
    const clinicId = this.props.match.params.id;
    if (clinicId) {
      this.setState({ showLoader: true });
      this.props.updateSelectedClinic(formData, clinicId);
    }
    else {
      this.setState({ clinicFormData: formData, productPricePop: true });
    }
  }

  hideCity = (e) => {
    this.setState({ gcity: '', gstate: '', geoLocationClass: "googleLocationInput field_error" })
  }

  createClinicNow = () => {
    this.setState({ appliedClinicIdError: false })
    let formData = this.state.clinicFormData;
    if (this.state.apply_clinic == "true") {
      if (this.state.appliedClinicId == 0) {
        this.setState({ appliedClinicIdError: true })
        return false;
      }
      formData.apply_clinic = true;
    } else {
      formData.apply_clinic = false;
    }
    formData.appliedClinicId = this.state.appliedClinicId;
    this.setState({ showLoader: true, productPricePop: false })
    this.props.createClinic(formData);
  }

  dismissTimeZoneModal = (event) => {
    this.setState({ showTimeZoneModal: false })
  }

  handleTagChange = (value) => {
    let returnState = {
      userChanged: true
    }
    if (this.validEmail(value)) {
      returnState.appointment_notification_emails = value
    }
    this.setState(returnState);
  }

  validEmail = (emailAray) => {
    let multipleEmailClass = 'newInputField notiEmailInput';
    let isEmailValid = true;
    if (emailAray && emailAray.length > 0) {
      emailAray.map((obj, idx) => {
        if (!validator.isEmail(obj)) {
          multipleEmailClass = 'newInputField notiEmailInput field_error';
          isEmailValid = false
        }
      })
      this.setState({ multipleEmailClass: multipleEmailClass });
    }
    return isEmailValid;

  }
  handleClinicChange = (selectedOption, clinicID) => {
    let x = this.state.appliedClinicId
    x[clinicID] = selectedOption
    this.setState({ appliedClinicId: x, showLoader: true });

    let formData = {
      user_id: selectedOption.value,
      pos_invoice_item_id: clinicID
    }

    this.props.changeUserID(formData);
  }

  selectLocation2 = (place) => {
    let zipcode = "";
    let city = "";
    let state = "";
    let country = "";
    let address = "";
    let suiteNumber = "";
    let inputName = document.getElementById("new-input").value = "";

    if (place.address_components && place.name) {
      place.address_components.forEach((data) => {
        const type = data.types[0];

        if (["route", "street_number", "neighborhood"].includes(type)) {
          address = data.long_name ? (address + data.long_name + " ") : "";
        }

        if (type === "subpremise" && /\d+/g.test(data.long_name)) {
          suiteNumber = data.long_name;
        }

        if (
          [
            "locality",
            "postal_town",
            "administrative_area_level_2",
          ].includes(type) && !city
        ) {
          city = data.long_name || "";
        }

        if (type === "administrative_area_level_1") {
          state = data.short_name || "";
        }

        if (type === "country") {
          country = data.short_name || "";
        }

        if (["postal_code", "postal_code_prefix"].includes(type) && !zipcode) {
          zipcode = data.long_name || "";
        }

        if (address) {
          inputName = address.trim();
        }

        if (!address && !suiteNumber) {
          address = `${data.long_name} `;
        }

        document.getElementById("new-input").value = inputName;
      });

      if (suiteNumber) {
        this.setState({
          suite_number: suiteNumber,
        });
      }

      this.setState({
        gcity: city,
        gstate: state,
        country_name: country,
        address: address.trim(),
        zipcode: zipcode,
      });
    }
  }

  is24TimeFormat = () => {
    return this.props.currentAccountQuery?.data?.preferences?.timeFormat === ACCOUNT_TIME_FORMATS.h24;
  }

  handleChangeMultipleTimes = (selectedDay, daysArray) => {
    const selectedFromTime = this.state[`from_time_${selectedDay}`];
    const selectedToTime = this.state[`to_time_${selectedDay}`];
    const selectedFromAM = this.state[`from_ampm_${selectedDay}`];
    const selectedToAM = this.state[`to_ampm_${selectedDay}`];

    const newState = daysArray.reduce((acc, day) => ({
      ...acc,
      [`from_time_${day}`]: selectedFromTime,
      [`to_time_${day}`]: selectedToTime,
      [`day-${day}`]: true,
      [`from_ampm_${day}`]: selectedFromAM,
      [`to_ampm_${day}`]: selectedToAM,
      [`disableClass_${day}`]: 'fromandtoTime  col-sm-10 col-xs-9 no-padding'
    }), {})

    this.setState({
      ...newState,
      isCopyScheduleDropdownOpen: null
    })
  }


  render() {
    var enableDays = [];
    if (this.state.business_hours !== undefined && this.state.business_hours.length) {
      this.state.business_hours.map((obj, idx) => {
        enableDays.push(obj.day);
      })
    }
    var clinicName = [];
    this.state.clinicList !== undefined &&
      this.state.clinicList.map((obj, idx) => {
        return (
          clinicName.push(obj.clinic_name)
        )
      });

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="setting-setion">
              <div className="setting-container">
                <div className="setting-title">
                  {this.state.edit_clinic_header}
                  <Link to="/settings/clinics" className="pull-right cancelAction">
                    <img src={'../../../../images/close.png'} /></Link>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="settings-subtitle m-b-10">{this.state.edit_clinic_subheader}</div>
                    <div className="row">
                      <div className="col-sm-6 col-lg-4 col-xs-12 d-flex gap-8">
                        <ColorSelect 
                          size="small" 
                          value={this.state.clinic_color}
                          onChange={(nextColor) => this.setState({ clinic_color: nextColor })}
                          className="m-t-17" 
                          isError={this.state.clinic_color_Error} 
                        />
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.edit_clinic_Clinic_name}<span className="setting-require">*</span></div>
                          <input name="clinic_name" id="clinicname" className={this.state.clinic_name_Error === true ? "newInputField field_error" : "newInputField"} autoComplete="off" placeholder="Clinic Name" maxLength="255" type="text" value={this.state.clinic_name} onChange={this.handleInputChange} /></div>
                      </div>
                      {/* Clinic address */}
                      <div className='col-sm-6 col-lg-4 col-xs-12'>
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">Address<span className="setting-require">*</span></div>
                          <Autocomplete id='new-input' placeholder='Search Clinic Address'
                            className={this.state.address_Error == true ? 'newInputField field_error' : 'newInputField'}
                            onPlaceSelected={(place) => this.selectLocation2(place)}
                            types={[]}
                            fields={['address_component', 'formatted_address', 'geometry', 'name', 'place_id', 'plus_code', 'type', 'formatted_phone_number', 'international_phone_number', 'opening_hours', 'website']} />
                        </div>
                      </div>
                      <div className='col-sm-6 col-lg-4  col-xs-12'>
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">Suite Number</div>
                          <input name="suite_number" id="suite_number" className="newInputField" autoComplete="off" placeholder="Suite Number" maxLength="255" type="text" value={this.state.suite_number} onChange={this.handleInputChange} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{"City"}<span className="setting-require">*</span></div>
                          <input name="gcity" id="gcity" className={this.state.gcity_error === true ? "newInputField field_error" : "newInputField"} autoComplete="off" placeholder="City" maxLength="255" type="text" value={this.state.gcity} onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-3 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{"State"}<span className="setting-require">*</span></div>
                          <input name="gstate" id="gstate" className={this.state.gstate_Error === true ? "newInputField field_error" : "newInputField"} autoComplete="off" placeholder="State" maxLength="255" type="text" value={this.state.gstate} onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-3 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.edit_clinic_country}<span className="setting-require">*</span></div>
                          <select onChange={this.handleInputChange} name="country_name" id="country_name" className={(this.state.country_error) ? "newSelectField field_error" : "newSelectField"} placeholder="Country" value={this.state.country_name}>
                            <option value="select">Select Country</option>
                            {this.state.countryList !== undefined &&
                              this.state.countryList.map((obj, idx) =>
                                <option value={obj.country_code} key={idx}>{obj.country_name}</option>
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">Zipcode<span className="setting-require">*</span></div>
                          <div className={this.state.geoLocationClass}>
                            <input type='text' name='zipcode' className={this.state.zipcode_error == true ? 'newInputField field_error' : 'newInputField'} placeholder="Zipcode" value={this.state.zipcode} onChange={this.handleInputChange} />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-3 col-xs-12">
                        <div className="newInputFileldOuter relative m-t-17">
                          <div className="newInputLabel absolute top--10">
                            {this.state.edit_clinic_contact_no}<span className="setting-require">*</span>
                          </div>
                          {this.state.isRender && (
                            <InputPhone
                              variant="legacy-outlined"
                              value={this.state.contact_no || ''}
                              onChange={this.phoneNumberChanged}
                              isError={this.state.contactError}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.edit_clinic_time_zone}<span className="setting-require">*</span></div>
                          <select name="timezone" id="time_zone" onChange={this.handleInputChange} className={(this.state.timezone_Error === true) ? "newSelectField field_error" : "newSelectField"} placeholder="Timezone" value={this.state.timezone}>
                            <option value="select">Select</option>
                            {this.state.timezoneList !== undefined &&
                              this.state.timezoneList.map((obj, idx) =>
                                <option key={'timezoneList' + idx} value={obj.php_timezone}>{obj.timezone}</option>
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-8 col-xs-12">
                        <div className="newInputFileldOuter relative m-t-17">
                          <div className="newInputLabel absolute top--10">
                            {this.state.edit_clinic_sms_notif_phone}<span className="setting-require"></span>
                          </div>
                          {this.state.isRender && (
                            <InputPhone
                              variant="legacy-outlined"
                              value={this.state.sms_notifications_phone || ''}
                              onChange={this.phoneNumberChanged1}
                              isError={this.state.sms_notifications_phoneError}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-12 notificationEmail">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.edit_clinic_notif_email}<span className="setting-require"></span></div>
                          <div className="simpleInput div-email-tag-input auto-height p-t-5">
                            <TagsInput value={(this.state.appointment_notification_emails) ? this.state.appointment_notification_emails : []}
                              onChange={this.handleTagChange} addOnBlur={true} addKeys={[9, 13, 32]} onlyUnique={true} />
                          </div>
                          <span className="important-instruction m-t-5">{this.state.edit_clinic_sms_multiple_emails}</span>
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <div className="newInputFileldOuter">
                          <input type="checkbox" className="new-check" id="double-booking" name="allow_online_portal" checked={(this.state.allow_online_portal) ? "checked" : false} onChange={this.handleInputChange} />
                          <label htmlFor="double-booking">Clinic Available for Online Booking</label>
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <div className="newInputFileldOuter">
                          <input type="checkbox" className="new-check" id="ecommerce-ob" name="is_available_ecommerce" checked={(this.state.is_available_ecommerce) ? "checked" : false} onChange={this.handleInputChange} />
                          <label htmlFor="ecommerce-ob">Clinic Available for eCommerce Only</label>
                        </div>
                      </div>
                      <div className=" col-xs-12">
                        <div className="newInputFileldOuter">
                          <input type="checkbox" className="new-check" id="enabled-tips" name="is_tips_enabled" checked={this.state.is_tips_enabled} onChange={this.handleInputChange} />
                          <label htmlFor="enabled-tips">Do you want this clinic to have an option to accept Tips?</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12">
                    <div className="settings-subtitle m-b-10">{this.state.edit_clinic_business_hours}</div>
                    <div className="business-hours-outer equipment-hours m-b-40">
                      {this.state.clinic_business_hours.map((obj, idx) => {
                        let mainTimeId = idx + 1;
                        return (
                          <div className="datetimeparent" key={idx}>
                            <div className="col-sm-2 col-xs-3 no-padding">
                              <input
                                type="checkbox"
                                className="new-check"
                                id={"new-check" + mainTimeId}
                                name={"day-" + mainTimeId}
                                checked={this.state["day-" + mainTimeId]}
                                data-day-index={mainTimeId}
                                onChange={this.toggleTime}
                              />
                              <label
                                className="setting-week"
                                htmlFor={"new-check" + mainTimeId}
                              >
                                {obj}
                              </label>
                            </div>
                            <div
                              className={
                                this.state["disableClass_" + mainTimeId]
                              }
                            >
                              <div className="col-xs-4 newInputFileldOuter equip-field-outer no-padding-right">
                                <div className="newInputLabel">Open Hours</div>
                                {this.state["from_time_" + mainTimeId] !==
                                  undefined && (
                                  <InputMask
                                    name={"from_time_" + mainTimeId}
                                    mask="99:99"
                                    className={
                                      this.state[
                                        "from_time_class_" + mainTimeId
                                      ]
                                    }
                                    placeholder="00:00"
                                    value={
                                      this.state["from_time_" + mainTimeId]
                                    }
                                    onChange={this.handleInputChange}
                                    maskChar=""
                                    beforeMaskedValueChange={this.maskChange}
                                  />
                                )}
                                {!this.is24TimeFormat() && (
                                  <select
                                    className={
                                      this.state[
                                        "from_hours_format_" + mainTimeId
                                      ]
                                    }
                                    name={"from_ampm_" + mainTimeId}
                                    onChange={this.handleInputChange}
                                    value={
                                      this.state["from_ampm_" + mainTimeId]
                                    }
                                  >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </select>
                                )}
                              </div>
                              <div className="col-xs-4 newInputFileldOuter equip-field-outer pull-right no-padding-right">
                                <div className="newInputLabel">Close Hours</div>
                                {this.state["to_time_" + mainTimeId] !==
                                  undefined && (
                                  <InputMask
                                    name={"to_time_" + mainTimeId}
                                    mask="99:99"
                                    className={
                                      this.state["to_time_class_" + mainTimeId]
                                    }
                                    placeholder="00:00"
                                    value={this.state["to_time_" + mainTimeId]}
                                    onChange={this.handleInputChange}
                                    maskChar=""
                                    beforeMaskedValueChange={this.maskChange}
                                  />
                                )}
                                {!this.is24TimeFormat() && (
                                  <select
                                    className={
                                      this.state[
                                        "to_hours_format_" + mainTimeId
                                      ]
                                    }
                                    name={"to_ampm_" + mainTimeId}
                                    onChange={this.handleInputChange}
                                    value={this.state["to_ampm_" + mainTimeId]}
                                  >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </select>
                                )}
                                <span
                                  onClick={() => {
                                    if (this.state[`day-${mainTimeId}`]) {
                                      this.setState({
                                        isCopyScheduleDropdownOpen: mainTimeId,
                                      })}
                                  }}
                                  className={classes.copyIcon}
                                >
                                  <img src={CopyIcon} alt="copy" />
                                </span>

                                {this.state.isCopyScheduleDropdownOpen ===
                                  mainTimeId && (
                                  <CopyTimeDropdown
                                    close={() =>
                                      this.setState({
                                        isCopyScheduleDropdownOpen: null,
                                      })
                                    }
                                    isCopyScheduleDropdownOpen={
                                      this.state.isCopyScheduleDropdownOpen
                                    }
                                    handleChangeMultipleTimes={
                                      this.handleChangeMultipleTimes
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                      )}
                    </div>

                    <div className={(this.state.showModal ? 'overlay' : '')}></div>
                    <div id="filterModal" role="dialog" className={(this.state.showModal ? 'modal fade in displayBlock' : 'modal fade')}>
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" onClick={this.dismissModal}>×</button>
                            <h4 className="modal-title" id="model_title">{this.state.delete_confirmation}{this.state.showModal}</h4>
                          </div>
                          <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                            {this.state.clinic_delete_warning}
                          </div>
                          <div className="modal-footer" >
                            <div className="col-md-12 text-left" id="footer-btn">

                              <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.dismissModal}>{this.state.no_option}</button>
                              <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.deleteClinic}>{this.state.yes_option}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-xs-12'>
                    <div className="newInputFileldOuter m-t-35">
                      <div className="newInputLabel">Additional Location Information for Booking Portal</div>
                      <div className="setting-input-outer">
                        <textarea name="additional_location_booking" id="additional_location_booking" className="simpleTextarea h-130 scroll-y" type="textarea" value={this.state.additional_location_booking} onChange={this.handleInputChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={(this.state.productPricePop) ? "modalOverlay" : "modalOverlay no-display"}>
                <div className="small-popup-outer appointment-detail-main">
                  <div className="small-popup-header">
                    <div className="popup-name">{this.state.settings_price_of_products_msg}</div>
                    <a onClick={() => { this.setState({ productPricePop: !this.state.productPricePop }); this.props.history.push(`/settings/clinic/create`); }} className="small-cross">×</a>
                  </div>
                  <div className="small-popup-content">
                    <div className="juvly-container no-padding-bottom">
                      <div className="juvly-subtitle">{this.state.settings_you_are_adding} - {this.state.clinic_name}</div>
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">

                          </div>
                        </div>
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="setting-field-outer">
                              <div className="new-field-label">{this.state.settings_plz_select_how_u}</div>
                              <div className="new-field-label"></div>
                              <div className="basic-checkbox-outer">
                                <input id="radiobutton1" className="basic-form-checkbox" name="apply_clinic" type="radio" value={"false"} onChange={this.handleInputChange} checked={(this.state.apply_clinic == "false") ? 'checked' : false} />
                                <label className="basic-form-text" htmlFor="radiobutton1">{this.state.settings_i_dont_want}</label>
                              </div>
                              <div className="new-field-label"></div>
                              <div className="basic-checkbox-outer">
                                <input id="radiobutton2" className="basic-form-checkbox" name="apply_clinic" type="radio" value={"true"} onChange={this.handleInputChange} checked={(this.state.apply_clinic == "true") ? 'checked' : false} />
                                <label className="basic-form-text" htmlFor="radiobutton2">{this.state.settings_copy_price}</label>
                              </div>
                            </div>
                          </div>
                          <div className={(this.state.apply_clinic == "false") ? "newInputFileldOuter no-display" : "newInputFileldOuter"}>

                            <div className="new-field-label">{this.state.settings_clinic}<span className="setting-require">*</span></div>
                            <div className="setting-input-outer">

                              <select name="appliedClinicId" id="time_zone" onChange={this.handleInputChange} className={this.state.appliedClinicIdError === true ? "newSelectField field_error" : "newSelectField"} value={this.state.appliedClinicId}>
                                <option value="select">{this.state.settings_select}</option>
                                {this.state.clinicList !== undefined &&
                                  this.state.clinicList.map((obj, idx) =>
                                    <option value={obj.id} key={"clinics-" + idx}>{obj.clinic_name}</option>
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-static">
                    <a className="new-blue-btn pull-right" onClick={this.createClinicNow}>{this.state.user_save_btn_text}</a>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                {(this.state.id > 0 && this.state.userType == "superadmin") &&
                  <a className="new-red-btn pull-left confirm-model" onClick={this.showDeleteModal}>{this.state.edit_clinic_delete_button}</a>
                }

                <button type="button" id="save_clinic" className="new-blue-btn pull-right" onClick={this.handleSubmit}>{this.state.saveBtn}</button>
                <Link to="/settings/clinics" className="new-white-btn pull-right cancelAction">
                  {this.state.editUsers_CancelBtn}</Link>

                <div className={(this.state.showTimeZoneModal ? 'overlay' : '')}></div>
                <div id="filterModal" role="dialog" className={(this.state.showTimeZoneModal ? 'modal fade in displayBlock' : 'modal fade')}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" onClick={this.dismissTimeZoneModal}>×</button>
                        <h4 className="modal-title" id="model_title">{this.state.delete_confirmation}{this.state.showModal}</h4>
                      </div>
                      <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                        {this.state.setting_clinic_timezone_change_msg_confirmation}
                      </div>
                      <div className="modal-footer" >
                        <div className="col-md-12 text-left" id="footer-btn">
                          <button type="button" className="btn btn-success pull-right " data-dismiss="modal" onClick={this.handleFormSubmit.bind(this, this.state.business_hours)}>{this.state.yes_option}</button>
                          <button type="button" className="btn  logout pull-right m-r-10" data-dismiss="modal" onClick={this.dismissTimeZoneModal}>{this.state.label_cancel}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {this.state.showErrorDeleteClinicModal &&
            <RestrictionModal closeModal={() => this.setState({ showErrorDeleteClinicModal: false })}>
              {<p className='text-center'>
                You cannot delete a clinic that has active {this.state.delete_validation_type}. <br /> Please cancel the patient {this.state.delete_validation_type} that are associated with this clinic and then you can delete it.
              </p>}
            </RestrictionModal>}
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.ClinicReducer.action === "SELECTED_CLINIC_LIST") {
    if (state.ClinicReducer.data.status != 200) {
      toast.dismiss()
      toast.error(languageData.global[state.ClinicReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.clinicData = state.ClinicReducer.data;
    }
  } else if (state.ClinicReducer.action === "CREATE_CLINIC") {
    if (state.ClinicReducer.data.status != 201) {
      returnState.user = state.ClinicReducer.data;
      returnState.showLoader = false
      toast.dismiss()
      toast.error(languageData.global[state.ClinicReducer.data.message]);
    }
    else {
      returnState.redirect = true;
      toast.dismiss();
      toast.success(languageData.global[state.ClinicReducer.data.message]);
    }
  }
  else if (state.ClinicReducer.action === 'UPDATE_SELECTED_CLINIC') {
    if (state.ClinicReducer.data.status != 200) {
      toast.dismiss()
      toast.error(languageData.global[state.ClinicReducer.data.message]);
      returnState.showLoader = false
    }
    else {
      returnState.redirect = true;
      toast.dismiss();
      toast.success(languageData.global[state.ClinicReducer.data.message]);
    }
    return returnState;
  } else if (state.ClinicReducer.action === "TIMEZONE_LIST") {
    if (state.ClinicReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      returnState.timezoneList = state.ClinicReducer.data;
    }
  } else if (state.ClinicReducer.action === "COUNTRIES_LIST") {
    if (state.ClinicReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      returnState.countryList = state.ClinicReducer.data;
    }
  } else if (state.ClinicReducer.action === "DEFAULT_CLINIC_DATA") {
    if (state.ClinicReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      returnState.defaultClinicData = state.ClinicReducer.data;
    }
  } else if (state.ClinicReducer.action === 'DELETE_CLINIC') {
    if (state.ClinicReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      returnState.redirect = true;
      returnState.message = languageData.global[state.ClinicReducer.data.message];
    }
  }
  returnState.deleteClinicData = state.ClinicReducer.deleteClinicData;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchSelectedClinic: fetchSelectedClinic,
    createClinic: createClinic,
    updateSelectedClinic: updateSelectedClinic,
    fetchAllTimezone: fetchAllTimezone,
    fetchAllCountries: fetchAllCountries,
    deleteClinic: deleteClinic,
    fetchDefaultClinicData: fetchDefaultClinicData,
    exportEmptyData: exportEmptyData
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withCurrentAccountQuery(CreateEditClinics));
