import React from "react";
import { Trans } from "react-i18next";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import { tSales } from "../../../../../../../../i18n/useAppTranslation";
import { InputCurrency } from "../../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import { Select } from "../../../../../../../../shared/Select/Select";
import { CardReaderDesc } from "../../../../../shared/CardReaderDesc/CardReaderDesc";
import { LoadingAlert } from "../../../../../../../../boxes/LoadingAlert/LoadingAlert";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { useCardReaders } from "../../../../../hooks/useCardReaders";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { usePaymentFlow } from "../../../../../hooks/usePaymentFlow";
import RemainingBalanceDue from "../../../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentFormModal = ({
  form,
  hasError,
  changeFormValue,
  errors,
  watchPaymentsChange,
  transferPaymentToReader,
  isValid,
  isTipsApplying,
  submit,
  showTipsModal,
}) => {
  const { invoice } = useInvoice();
  const { cardReaders, isCardReadersLoading, setDefaultCardReader } =
    useCardReaders();
  const { paymentViewClose } = useInvoiceActions();
  const { isTipsAvailable } = usePaymentFlow();

  const handleSubmit = () => {
    if (isTipsAvailable) {
      showTipsModal.update(true);
    } else {
      submit();
    }
  };

  const readerOptions = cardReaders.map((r) => ({
    label: `${r.serialNumber} (${r.clinic.name})`,
    value: r.id,
  }));

  const onSelectReader = (option) => {
    const cardReader = cardReaders.find((r) => r.id);
    changeFormValue("readerId", option.value);
    setDefaultCardReader({
      clinicId: cardReader?.clinic?.id,
      cardReaderId: option.value,
    });
  };

  return (
    <>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.cardReader")}
          </InputLabel>
          <Select
            size="medium"
            isError={hasError("readerId")}
            isLoading={isCardReadersLoading}
            value={readerOptions.find((o) => o.value === form.readerId)}
            onChange={onSelectReader}
            options={readerOptions}
          />
          {hasError("readerId") && <InputError>{errors.readerId}</InputError>}
        </ModalCharge.InputWrap>
        {form.readerId && (
          <ModalCharge.InputWrap>
            <CardReaderDesc
              serialNumber={
                cardReaders?.find((r) => r.id === form.readerId)?.serialNumber
              }
              clinicName={
                cardReaders.find((r) => r.id === form.readerId)?.clinic?.name
              }
            />
          </ModalCharge.InputWrap>
        )}
        {watchPaymentsChange.isLoading && (
          <LoadingAlert>
            <Trans
              i18nKey={tSales("checkoutInvoice.warn.awaitingForReaderPayment")}
              components={{
                b: <b />,
              }}
            />
          </LoadingAlert>
        )}
        <RemainingBalanceDue amount={form.amount} />
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        isCharging={transferPaymentToReader.isLoading}
        onCharge={handleSubmit}
        isCancelDisabled={watchPaymentsChange.isLoading}
        isChargeDisabled={
          !isValid ||
          transferPaymentToReader.isLoading ||
          watchPaymentsChange.isLoading ||
          isTipsApplying
        }
      />
    </>
  );
};

export default PaymentFormModal;
