import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import StripeCheckoutPayment from "../components/Manual/StripeCheckoutPayment";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { composeAmountSchema } from "../../../../SalesCheckoutInvoice.schemas";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import RemainingBalanceDue from "../../../../shared/RemainingBalanceDue/RemainingBalanceDue";

function FormManual() {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, noTipAmount, isGuest } = useInvoice();
  const { paymentViewClose } = useInvoiceActions();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const onClose = () => {
    paymentViewClose();
  };

  const schema = yup.object({
    paymentAmount: composeAmountSchema(
      { amountToPay: noTipAmount, currency: invoice?.currency },
      true,
    ),
  });

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: {
      paymentAmount: noTipAmount,
    },
    validationSchema: schema,
    onSubmit: () => {
      setShowPaymentForm(true);
    },
  });

  return (
    <div className="p-t-10 p-b-20">
      {showPaymentForm ? (
        <div className="p-t-10 p-b-20">
          <StripeCheckoutPayment
            saveCard={saveCard}
            paymentAmount={values.paymentAmount}
            setShowPaymentForm={setShowPaymentForm}
          />

          {!isGuest && (
            <div className="col-sm-6 m-t-25">
              <Checkbox
                size="small"
                label={tSales("checkoutInvoice.saveCcToAccount")}
                isChecked={saveCard}
                onChange={() => setSaveCard(!saveCard)}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-column">
          <div className="col-sm-8 p-b-10">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={values.paymentAmount}
              isError={errors.paymentAmount}
              onChange={(value) => {
                setFieldValue("paymentAmount", value);
              }}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
            {errors.paymentAmount && (
              <InputError>{errors.paymentAmount}</InputError>
            )}
          </div>
          <div className="col-sm-8">
            <RemainingBalanceDue amount={values.paymentAmount} />
          </div>

          <ModalCharge.Footer
            onClose={onClose}
            isCharging={false}
            onCharge={handleSubmit}
            className={"no-margin p-l-15 p-r-15"}
          />
        </div>
      )}
    </div>
  );
}

export default FormManual;
