import React, { useState } from "react";
import cx from "clsx";
import styles from "../scss/clinics.module.scss";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import ReactTooltip from "react-tooltip";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Toggle } from "../../../../../shared/Toggle/Toggle";
import { ACCOUNT_TIME_FORMATS } from "../../../../../consts/api";
import { useUpdateShowSales } from "../hooks/useUpdateShowSales";
import { useUpdateShowSalesSummary } from "../hooks/useUpdateShowSalesSummary";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../../services/UINotificationService";
import { withRouter } from "react-router";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";

const ClinicCard = ({ obj, idx, history }) => {
  const accountQuery = useCurrentAccountQuery();
  const { data: currentUser } = useCurrentUserQuery();

  const [showSales, setShowSales] = useState(obj.show_in_sales);
  const [showSalesSummary, setShowSalesSummary] = useState(
    obj.show_in_sales_summary,
  );

  const { mutate: updateShowSales, isLoading: isUpdatingShowSales } =
    useUpdateShowSales(obj.id);
  const {
    mutate: updateShowSalesSummary,
    isLoading: isUpdatingShowSalesSummary,
  } = useUpdateShowSalesSummary(obj.id);

  const userEdit = (id) => {
    history.push(`/settings/clinic/${id}/edit`);
  };

  const to12Time = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  const prepareTime = (time) => {
    if (
      accountQuery?.data?.preferences?.timeFormat === ACCOUNT_TIME_FORMATS.h12
    ) {
      return to12Time(time);
    }
    return time?.split(":")?.slice(0, 2)?.join(":");
  };

  const dayOfWeekAsInteger = (day) => {
    var weekday = new Array(7);
    weekday[2] = "Mon";
    weekday[3] = "Tue";
    weekday[4] = "Wed";
    weekday[5] = "Thu";
    weekday[6] = "Fri";
    weekday[7] = "Sat";
    weekday[1] = "Sun";
    return weekday[day];
  };

  const handleUpdateShowSales = () => {
    updateShowSales(
      { show_in_sales: Number(!showSales) },
      {
        onSuccess: () => {
          setShowSales(!showSales);
        },
        onError: () =>
          uiNotification.error("Something went wrong. Try again later"),
      },
    );
  };

  const handleUpdateShowSalesSummary = () => {
    updateShowSalesSummary(
      { show_in_sales_summary: Number(!showSalesSummary) },
      {
        onSuccess: (data) => {
          setShowSalesSummary(!showSalesSummary);
        },
        onError: () =>
          uiNotification.error("Something went wrong. Try again later"),
      },
    );
  };

  return (
    <div
      className={cx(styles.clinicCard, !obj.status && styles.disabledClinic)}
    >
      <div className={styles.cardTitle}>
        <h3 className={styles.title} data-tip data-for={"smsToolTip" + idx}>
          {obj.clinic_name}
        </h3>
        {obj.status && (
          <button
            className="easy-link pull-right"
            key={idx}
            onClick={() => userEdit(obj.id)}
            title="Edit Clinic"
          >
            <i className="fa fa-pencil-alt m-r-5 " />
            Edit
          </button>
        )}
      </div>
      <ReactTooltip id={"smsToolTip" + idx} effect="solid">
        <span>{obj.clinic_name}</span>
      </ReactTooltip>
      <div className={styles.cardBody}>
        <div className={styles.address}>
          <p>{obj.contact_no}</p>
          <p>{`${obj.address}${
            obj.suite_number ? `, ${obj.suite_number} ` : ""
          }`}</p>
          <p>{`${obj.clinic_city} ${obj.clinic_state} ${obj.clinic_zipcode}`}</p>
          <p>{obj.country} </p>
        </div>
        {Boolean(obj.clinic_business_hours.length) && (
          <div className={styles.businessHours}>
            <span className={styles.businessHoursTitle}>Business hours</span>
            <div className={styles.hoursContainer}>
              {obj.clinic_business_hours.map((innobj, innidx) => {
                var time1 = prepareTime(`${innobj.to_time}`);
                var time = prepareTime(`${innobj.from_time}`);
                var dayCon = dayOfWeekAsInteger(`${innobj.day}`);
                return (
                  <div className={styles.hourRow} key={innidx}>
                    <span className={styles.day}>{dayCon}</span>
                    <span>
                      {time} - {time1}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!obj.status && currentUser?.isMainAdmin && (
          <div className={styles.togglesContainer}>
            <div className={styles.togglesInnerContainer}>
              <>
                <InputLabel className={styles.toggle}>Show in Sales</InputLabel>
                {isUpdatingShowSales ? (
                  <CircularProgress size="small" />
                ) : (
                  <Toggle
                    isEnabled={showSales}
                    onChange={handleUpdateShowSales}
                  />
                )}
              </>
              <>
                <InputLabel className={styles.toggle}>
                  Show in Sales Summary
                </InputLabel>
                {isUpdatingShowSalesSummary ? (
                  <CircularProgress size="small" />
                ) : (
                  <Toggle
                    isEnabled={showSalesSummary}
                    onChange={handleUpdateShowSalesSummary}
                  />
                )}
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(ClinicCard);
