import React from "react";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import ViewTipsModal from "../../../../ViewTipsModal/ViewTipsModal";
import { svg } from "../../../../../../../../assets/svg";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { usePaymentsChangeWatcher } from "../../../../../hooks/usePaymentsChangeWatcher";
import { dispatch } from "../../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";

const PaymentTipsModal = ({ isTipsApplying, form, showTipsModal }) => {
  const { invoice } = useInvoice();
  const { transferPaymentToReader } = useInvoiceActions();
  const { watchPaymentsChange } = usePaymentsChangeWatcher({
    onPaymentsUpdated: () =>
      dispatch(checkoutInvoice.actions.paymentViewClose()),
  });

  const totalAmountToPay =
    parseFloat(form.amount) + parseFloat(invoice.tipAmount);

  const onClose = () => {
    showTipsModal.update(false);
  };

  const handleSubmit = () => {
    transferPaymentToReader
      .initiate({
        amount: totalAmountToPay,
        cardReaderId: form.readerId,
      })
      .then(() => {
        watchPaymentsChange.initiate();
        uiNotification.success(
          tSales("checkoutInvoice.success.transferPaymentToReader"),
        );
      })
      .catch(onClose);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
      onCharge={handleSubmit}
      isCharging={transferPaymentToReader.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
    </ModalCharge>
  );
};

export default PaymentTipsModal;
