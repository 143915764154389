import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { tSales } from "../../../../../../i18n/useAppTranslation";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { AvailableBalance } from "../../../shared/AvailableBalance/AvailableBalance";
import { formatCurrency } from "../../../../../../utilities/general";
import RemainingBalanceDue from "../../../shared/RemainingBalanceDue/RemainingBalanceDue";

const PaymentFormModal = ({
  form,
  errors,
  hasError,
  changeFormValue,
  cherryPatient,
}) => {
  const { invoice } = useInvoice();

  return (
    <>
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <AvailableBalance
          amount={formatCurrency(
            cherryPatient?.balance?.amount,
            invoice?.currency,
          )}
        />
        <RemainingBalanceDue amount={form.amount} />
      </ModalCharge.InputWrap>
    </>
  );
};

export default PaymentFormModal;
