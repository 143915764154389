import React from "react";
import classes from "../../widgets/Amount/Amount.module.scss";
import { TipsApplier } from "../../boxes/TipsApplier/TipsApplier";
import { tSales } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { MergedInvoice } from "../../widgets/Amount/MergedInvoice/MergedInvoice";
import styles from "./scss/viewTipsModal.module.scss";
import TipsModalField from "./components/TipsModalField";
import { formatCurrency } from "../../../../../utilities/general";

const ViewTipsModal = ({ amountToPay, tipsDisabled }) => {
  const { invoice, beforeTipAmount, currencySymbol } = useInvoice();
  const { applyTip } = useInvoiceActions();

  const subtotalAmount = invoice?.subtotalAmount;

  const onApplyCustomTip = ({ amount }) => {
    applyTip.initiate({ tipAmount: amount });
  };

  const onApplyTip = (tipOptionValue) => {
    applyTip.initiate({
      tipAmount: applyTip.optionToAmount(tipOptionValue),
    });
  };

  return (
    <div className={styles.modalView}>
      <TipsModalField
        label="Invoice Amount"
        amount={beforeTipAmount}
        info={formatCurrency(beforeTipAmount, currencySymbol)}
      />
      <div className={classes.tipsWrap}>
        <div className={classes.tipHeader}>
          {tSales("checkoutInvoice.tips.header")}
        </div>
        {!invoice?.isMerger && (
          <TipsApplier
            onApply={onApplyTip}
            onApplyCustom={onApplyCustomTip}
            tipAmount={invoice?.tipAmount}
            isApplying={applyTip.isLoading || tipsDisabled}
            currencySymbol={invoice?.currencySymbol}
            subtotalAmount={subtotalAmount}
          />
        )}
        {invoice?.isMerger &&
          invoice?.mergedInvoices?.map(
            (mergedInvoice, index) =>
              mergedInvoice.isTipsEnabled && (
                <MergedInvoice key={index} mergedInvoice={mergedInvoice} />
              ),
          )}
        <span className={styles.tip}>
          Tip Amount:{" "}
          <span className={styles.amount}>
            {formatCurrency(invoice.tipAmount, currencySymbol)}
          </span>{" "}
        </span>
      </div>
      <TipsModalField
        label="Total Due"
        amount={invoice.totalAmount}
        info={`${formatCurrency(
          beforeTipAmount,
          currencySymbol,
        )} + ${formatCurrency(invoice.tipAmount, currencySymbol)} tip`}
      />
      <TipsModalField
        label="Payment Amount"
        amount={amountToPay}
        tips={invoice.tipAmount}
      />
    </div>
  );
};

export default ViewTipsModal;
